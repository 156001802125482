import React from 'react';
import * as ReactDOMClient from 'react-dom/client';
import Modal from 'react-modal';

import App from './App';

import './assets/css/index.css';
import './assets/css/animations.css';

const container = document.getElementById('root');

Modal.setAppElement(container!);

const root = ReactDOMClient.createRoot(container!);

root.render(
    <React.StrictMode>
        <App />
    </React.StrictMode>
);
