import { isMobile } from 'react-device-detect';
import { createState } from '../../lib/create-state';
import { meta } from './meta';

export type ContentfulType = {
    spaceId: string;
    environmentId: string;
    accessToken: string;
};

export type SentryType = {
    trace: string;
    dsn: string;
};

export type SiteDataType = {
    contentful: ContentfulType;
    sentry: SentryType;
};

export type SiteType = {
    siteCode: string;
    siteId: number;
    homeUrl: string;
    helpUrl: string;
    responsibleUrl: string;
    data: SiteDataType;
};

export type DisplayType = {
    enableAutoBuyLossLimit: boolean;
    showTotalTickets: boolean;
    defaultCurrency: string;
    enableCurrencyToggle: boolean;
    enableFreeBuyConfirm: boolean;
    enableUnlimitedAutoBuy: boolean;
    enableMaxCardAutoBuy: boolean;
    autoBuyLossLimitAmount: number;
    autoBuyGameLimit: number;
};

export type RoomType = {
    skin: string;
    announcer: string;
    caller: string;
    locale: string;
    localeId: number;
    gameId: string;
};

export type ScreenConfigType = {
    site: SiteType;
    display: DisplayType;
    room: RoomType;
    casinoAssetsURL: string;
};

export type SideGamesType = {
    name: string;
    type: string;
    configId: number;
    style: string;
    launch: string;
};

export type ScreenConfigState = {
    env: string;
    screenConfig: ScreenConfigType;
    sideGames: SideGamesType[];
    language: string;
};

export const DEFAULT_SCREEN_CONFIG_STATE = {
    env: '',
    screenConfig: {
        site: {
            siteCode: '',
            siteId: 0,
            homeUrl: '',
            helpUrl: '',
            responsibleUrl: '',
            data: {} as SiteDataType,
        },
        display: {
            enableAutoBuyLossLimit: false,
            showTotalTickets: false,
            defaultCurrency: '',
            enableCurrencyToggle: false,
            enableFreeBuyConfirm: false,
            enableUnlimitedAutoBuy: true,
            enableMaxCardAutoBuy: true,
            autoBuyLossLimitAmount: 100.0,
            autoBuyGameLimit: 10,
        },
        room: {
            skin: '',
            announcer: '',
            caller: '',
            locale: '',
            localeId: 0,
            gameId: '',
        },
        casinoAssetsURL: '',
    },
    sideGames: [],
    language: '',
};

export const [screenConfig, resetScreenConfig] = createState<ScreenConfigState>(
    DEFAULT_SCREEN_CONFIG_STATE
);

export const getScreenConfig = async () => {
    meta.loaders.screenConfig.set(true);

    try {
        const searchParams = new URLSearchParams(window.location.search);

        if (isMobile) {
            searchParams.set('MOBILE', 'true');
        }

        const response = await fetch(
            '/screen-config?' + searchParams.toString()
        );
        const newConfig = (await response.json()) as ScreenConfigState;

        screenConfig.set(newConfig);
        meta.loaded.screenConfig.set(true);
    } catch (error) {}

    meta.loaders.screenConfig.set(false);
};
