import { FC, HTMLAttributes } from 'react';
import styled from 'styled-components';
import { useSoundHandler } from '../../hooks/use-sound-handler';

export type SettingsMenuButtonProps = HTMLAttributes<HTMLButtonElement>;

export const SettingsMenuButtonContainer = styled.button`
    width: 27.125rem;
    border-radius: 0.969rem;
    margin-bottom: 1.5rem;
    padding: 0.75rem;
    cursor: pointer;

    ${({ theme: { settingsMenuButton } }) => `
        box-shadow: inset 0 0.25rem 0 0 ${settingsMenuButton.boxShadow.first},
        inset 0 -0.25rem 0 0 ${settingsMenuButton.boxShadow.second};
        border: solid 0.094rem ${settingsMenuButton.border};
        background-image: linear-gradient(to bottom, ${settingsMenuButton.backgroundGradient.upper},
            ${settingsMenuButton.backgroundGradient.lower} 99%);
    `};
`;
export const SettingsMenuButtonText = styled.div`
    font-family: Signika-Bold;
    font-size: 2.5rem;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: center;
    color: ${({
        theme: {
            settingsMenuButton: { color },
        },
    }) => color};
`;

export const SettingsMenuButton: FC<SettingsMenuButtonProps> = ({
    children,
    ...props
}) => {
    const onClick = useSoundHandler(props.onClick, 'click');
    return (
        <SettingsMenuButtonContainer {...props} onClick={onClick}>
            <SettingsMenuButtonText>{children}</SettingsMenuButtonText>
        </SettingsMenuButtonContainer>
    );
};
