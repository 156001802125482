import { FC } from 'react';
import styled, { css, DefaultTheme, useTheme } from 'styled-components';
import { useTranslation } from 'react-i18next';
import { BuyButton } from '../BuyButton';
import { BingoCardPattern } from '../BingoCardPattern';
import { CommonButton, CommonButtonText } from '../CommonButton';
import { CountdownTimer } from '../CountdownTimer';
import { GamePart, GamePartDot } from '../GamePart';
import { GameType, GameTypeProps } from '../GameType';
import { InfoButton } from '../InfoButton';
import { ModalTitle } from '../ModalTitle';
import { PlayersCards } from '../PlayersCards';
import { SpecialBanner } from '../SpecialBanner';
import { IncrementButton } from '../IncrementButton';
import { DecrementButton } from '../DecrementButton';
import { CommonModal, CommonModalContainer } from '../CommonModal';
import { InitialValue } from '../PlusMinusInput';
import { useSoundHandler } from '../../hooks/use-sound-handler';
import { BuyModalProps } from './mobile';

export const fontStyle = css`
    font-family: Signika-Bold;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    letter-spacing: normal;
`;

export const buyModalCardsStyle = ({
    theme: {
        buyModal: { cards },
    },
}: {
    theme: DefaultTheme;
}) => `
    text-align: center;
    border: solid 0.063rem ${cards.border};
    background-color: ${cards.background};
    box-shadow: inset 0 0.25rem 0 0 ${cards.boxShadow.first},
        0 0.25rem 0 0 ${cards.boxShadow.second};
`;

export const enabledStyle = ({ enabled = true }: { enabled?: boolean }) => {
    if (enabled) {
        return;
    }

    return css`
        opacity: 0.5;
        cursor: not-allowed;
    `;
};

export const autoBuyButtonStyle = ({
    theme: {
        buyModal: { autobuy },
    },
}: {
    theme: DefaultTheme;
}) => {
    return `
        color: ${autobuy.color};
        box-shadow: inset 0 0.188rem 0 0 ${autobuy.boxShadow.first},
            inset 0 -0.188rem 0 0 ${autobuy.boxShadow.second};
        border: solid 0.075rem ${autobuy.border};
        background-image: linear-gradient(to bottom, ${autobuy.backgroundGradient.upper}, ${autobuy.backgroundGradient.lower} 99%);
    `;
};

export const autoBuyCircleStyle = ({
    activation,
    theme: {
        buyModal: { circle },
    },
}: {
    theme: DefaultTheme;
    activation: boolean;
}) => `

    ${
        activation
            ? `
    background-color: ${circle.background.active};
    box-shadow: inset 0 0.125rem 0 0 ${circle.boxShadow.active.first}, inset 0 -0.125rem 0 0 ${circle.boxShadow.active.second};
    `
            : `
            background-color: ${circle.background.inactive};
    box-shadow: inset 0 0.125rem 0 0 ${circle.boxShadow.inactive.first}, inset 0 -0.125rem 0 0 ${circle.boxShadow.inactive.second};
            `
    }
`;

export const clearButtonStyle = ({
    theme: {
        buyModal: { clear },
    },
}: {
    theme: DefaultTheme;
}) => `
    box-shadow: inset 0 0.313rem 0 0 ${clear.boxShadow.first},
        inset 0 -0.313rem 0 0 ${clear.boxShadow.second};
    background-image: linear-gradient(to bottom, ${clear.backgroundGradient.upper}, ${clear.backgroundGradient.lower} 99%);
`;

export const plusMinusButtonStyle = css`
    width: 5.25rem;
    height: 4.75rem;
    font-size: 4rem;
`;

export const BuyModalContainer = styled(CommonModalContainer)`
    width: 64.5rem;
    left: 16.875rem;
    margin-top: 6rem;
    padding: 1.625rem 0;
`;

export const FlexRowWrapper = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
`;

export const FlexColWrapper = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
`;

const Divider = styled.hr`
    width: 80.5%;
    margin-left: 0;
    border-width: 0;
    height: 0.125rem;
    background-color: ${({ theme: { primaryPanel } }) =>
        primaryPanel.color.divider};
`;

export const ModalTitleWrapper = styled(ModalTitle)`
    font-size: 2.875rem;
    line-height: 2.875rem;
`;

export const TimerWrapper = styled(CountdownTimer)`
    font-size: 4.75rem;
    padding: 0.5rem;
    border-radius: 0.5rem;
    margin-top: 1rem;

    ${buyModalCardsStyle}
`;

export const GameTitleWrapper = styled.div`
    font-size: 1.75rem;
    line-height: 2.5rem;
    width: 80%;
    ${fontStyle};
`;

export const BuyModalLabel = styled.div`
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    color: ${({
        theme: {
            buyModal: { color },
        },
    }) => color.label};
`;

export const PatternNamePriceLabel = styled.div`
    display: flex;
`;

export const LinkedGameIcon = styled.img`
    width: 1rem;
    height: 1rem;
    margin-left: 0.5rem;
`;

export const GameContinuedIcon = styled.img`
    width: 2.5rem;
    height: 2.5rem;
    margin-left: 0.5rem;
`;

export const PatternNameLabel = styled.div`
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    margin-right: 0.313rem;
`;

export const BuyModalValue = styled.span`
    color: ${({
        theme: {
            buyModal: { color },
        },
    }) => color.value};
`;

export const GameTitleJackpot = styled.div`
    color: ${({
        theme: {
            buyModal: { color },
        },
    }) => color.jackpot};
`;

export const GamePartContainer = styled.div`
    display: flex;
    justify-content: flex-end;
    align-items: flex-end;
    align-self: stretch;
    width: 20%;
    padding-bottom: 0.625rem;
`;

export const GamePartWrapper = styled(GamePart)`
    width: 100%;
    height: 1rem;
    justify-content: flex-end;
    margin-left: 0.5rem;

    ${GamePartDot} {
        width: 0.938rem;
        height: 0.938rem;
    }
`;

export const MarginedWrapper = styled.div`
    margin-top: -1.25rem;
`;

export const GameTypeWrapper = styled(GameType)`
    width: 3.75rem;
    height: 3.75rem;
`;

export const PlayersCardsWrapper = styled(PlayersCards)`
    background-image: none;
    height: 2.5rem;

    ${buyModalCardsStyle};
`;

export const GamePatternWrapper = styled.div<GameTypeProps>`
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-basis: ${({ gameType }) => (gameType === 90 ? '33%' : '25%')};
`;

export const AccountCard = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    width: 33%;
    height: 3rem;
    font-size: 1.5rem;
    ${fontStyle};
    ${buyModalCardsStyle};
`;

export const AccountCardLabel = styled(BuyModalLabel)`
    margin-right: 0.5rem;
`;

export const SpecialBannerWrapper = styled(SpecialBanner)`
    width: 20.75rem;
`;

export const SpecialBannerPlaceholder = styled.div`
    height: 3.75rem;
`;

export const BlueButton = styled.button`
    display: flex;
    justify-content: center;
    align-items: center;
    height: 3.25rem;
    font-size: 1.438rem;
    border-radius: 0.75rem;
    cursor: pointer;

    ${autoBuyButtonStyle};
    ${enabledStyle};
    ${fontStyle};
`;

export const PickCardsButton = styled(BlueButton)`
    width: 17.375rem;
`;

export const AutoBuyButton = styled(BlueButton)`
    width: 13.125rem;
`;

export const DecrementButtonWrapper = styled(DecrementButton)`
    ${plusMinusButtonStyle};
`;

export const IncrementButtonWrapper = styled(IncrementButton)`
    ${plusMinusButtonStyle};
`;

export const PickCardImage = styled.img`
    width: 1.8125rem;
    height: 2rem;
    margin-right: 0.59375rem;
`;

export const AutobuyCircle = styled.div<{ activation: boolean }>`
    width: 1.125rem;
    height: 1.125rem;
    border-radius: 50%;
    margin-right: 1.313rem;

    ${autoBuyCircleStyle};
`;

export const TicketInfoContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    font-size: 1.5rem;
    width: 100%;
    height: 5rem;
    border-radius: 0.375rem;
    padding: 0.75rem 0.875rem;

    ${fontStyle};
    ${buyModalCardsStyle};
`;

export const ClearButton = styled(CommonButton)`
    width: 100%;
    height: 4.375rem;

    ${enabledStyle};
    ${clearButtonStyle};
    ${CommonButtonText} {
        font-size: 2rem;
    } ;
`;

export const MaxButton = styled(CommonButton)`
    width: 100%;
    height: 4.375rem;
    margin-bottom: 0.75rem;

    ${enabledStyle};
    ${CommonButtonText} {
        font-size: 2rem;
    } ;
`;

export const SelectedTicketCard = styled(InitialValue)<{ disabled: boolean }>`
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 10rem;
    font-size: 4.75rem;
    border-radius: 0.75rem;

    ${({ theme: { buyModal }, disabled }) => `
        border: 0.313rem solid ${buyModal.inputBorder};
        cursor: ${disabled ? 'not-allowed' : void 0};
        opacity: ${disabled ? 0.5 : 1};
    `};
`;

export const SelectedTicketLabel = styled(BuyModalLabel)`
    font-size: 1.5rem;
    ${fontStyle};
`;

export const BuyButtonWrapper = styled(BuyButton)`
    width: 100%;
`;

export const WageredLabelWrapper = styled.div`
    width: 100%;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    font-size: 1.5rem;
    margin-top: 1.5rem;
    padding: 0 7.5rem;

    ${fontStyle};
`;

export const BuyModalDesktop: FC<BuyModalProps> = ({
    cash,
    bonus,
    wagered,
    parts,
    special,
    gameType,
    gameTitle,
    gamePrize,
    gameCards,
    hasJackpot,
    jackpotName,
    jackpotCall,
    gamePlayers,
    patternName,
    patternType,
    boughtFreeTickets,
    ticketPrice,
    jackpotPrize,
    boughtTickets,
    totalPromoCards,
    isDisabled,
    countdownDate,
    gameLinked,
    gameContinued,
    currentPart,
    gameParts,
    stripOnly,
    activation,

    onBuy,
    onGameInfo,
    onAutoBuy,
    onPickCards,

    min,
    max,
    hasIncrementalChange,
    buying,
    tickets,
    freeTickets,
    total,
    increment,
    decrement,
    minimize,
    maximize,

    ...props
}) => {
    const { t } = useTranslation();

    const theme = useTheme();
    const { pickCards, link, starImage4 } = theme.images;

    const cardType = stripOnly
        ? t('modalPurchase.cardTypeStrips')
        : t('modalPurchase.cardTypeTickets');

    const $disabled = isDisabled || !hasIncrementalChange;

    const $onAutoBuy = useSoundHandler(onAutoBuy, 'click');
    const $onPickCards = useSoundHandler(onPickCards, 'click');
    const $onBuy = useSoundHandler(onBuy, 'buy');
    const $onGameInfo = useSoundHandler(onGameInfo, 'click');

    return (
        <CommonModal {...props} Container={BuyModalContainer}>
            <FlexRowWrapper
                style={{ marginBottom: '0.75rem', padding: '0 1.625rem' }}
            >
                <FlexColWrapper style={{ width: '77%' }}>
                    <ModalTitleWrapper title={t('modalPurchase.nextGame')} />
                    <Divider />
                    <FlexRowWrapper style={{ width: '100%' }}>
                        <GameTitleWrapper>
                            <BuyModalLabel>
                                {gameTitle}
                                {gameLinked && (
                                    <LinkedGameIcon
                                        src={link}
                                        alt="Link Icon"
                                    />
                                )}
                            </BuyModalLabel>
                            <PatternNamePriceLabel>
                                <PatternNameLabel>
                                    {patternName ? `${patternName}:` : ''}
                                </PatternNameLabel>
                                <BuyModalValue>{gamePrize}</BuyModalValue>
                            </PatternNamePriceLabel>
                            {hasJackpot && (
                                <GameTitleJackpot>
                                    {`${t('infoBox.jackpotFormatting', {
                                        jpName: jackpotName,
                                        jpCall: jackpotCall,
                                    })}: ${jackpotPrize}`}
                                </GameTitleJackpot>
                            )}
                        </GameTitleWrapper>
                        <FlexColWrapper
                            style={{
                                width: '20%',
                                alignItems: 'flex-end',
                                rowGap: '0.5rem',
                            }}
                        >
                            <MarginedWrapper>
                                <InfoButton handleClick={$onGameInfo} />
                                <GameTypeWrapper gameType={gameType} />
                            </MarginedWrapper>
                            <FlexRowWrapper>
                                <BingoCardPattern
                                    patternType={patternType}
                                    parts={parts}
                                    gameType={gameType}
                                    currentPart={currentPart - 1}
                                />
                                {gameContinued ? (
                                    <GameContinuedIcon
                                        src={starImage4}
                                        alt="Continued Game Icon"
                                    />
                                ) : (
                                    <GamePartWrapper
                                        gameParts={gameParts}
                                        currentPart={currentPart}
                                    />
                                )}
                            </FlexRowWrapper>

                            <FlexRowWrapper>
                                <PlayersCardsWrapper
                                    type="players"
                                    text={gamePlayers}
                                />
                                <PlayersCardsWrapper
                                    type="cards"
                                    text={gameCards}
                                />
                            </FlexRowWrapper>
                        </FlexColWrapper>
                    </FlexRowWrapper>
                </FlexColWrapper>
                <TimerWrapper to={countdownDate} />
            </FlexRowWrapper>
            <FlexRowWrapper
                style={{
                    marginBottom: '1.063rem',
                }}
            >
                <AccountCard>
                    <AccountCardLabel>
                        {t('modalPurchase.playerBalCash')}:
                    </AccountCardLabel>
                    <BuyModalValue>{cash}</BuyModalValue>
                </AccountCard>
                <AccountCard>
                    <AccountCardLabel>
                        {t('modalPurchase.playerBalBonus')}:
                    </AccountCardLabel>
                    <BuyModalValue>{bonus}</BuyModalValue>
                </AccountCard>
                <AccountCard>
                    <AccountCardLabel>
                        {t('modalPurchase.cardOrStripePrice', {
                            type: cardType,
                        })}
                        :
                    </AccountCardLabel>
                    <BuyModalValue>{ticketPrice}</BuyModalValue>
                </AccountCard>
            </FlexRowWrapper>
            <FlexRowWrapper style={{ padding: '0 1.625rem' }}>
                <FlexColWrapper style={{ width: '55%' }}>
                    <FlexRowWrapper
                        style={{ width: '100%', marginBottom: '0.75rem' }}
                    >
                        <PickCardsButton
                            onClick={isDisabled ? void 0 : $onPickCards}
                            enabled={!isDisabled}
                        >
                            <PickCardImage src={pickCards} alt="Pick Cards" />
                            {t('modalPurchase.btnPickCards')}
                        </PickCardsButton>
                        <AutoBuyButton onClick={$onAutoBuy}>
                            <AutobuyCircle activation={activation} />
                            {t('modalPurchase.btnAutobuy')}
                        </AutoBuyButton>
                    </FlexRowWrapper>
                    <FlexRowWrapper style={{ width: '100%' }}>
                        <FlexColWrapper
                            style={{
                                width: '53%',
                                alignItems: 'center',
                                rowGap: '0.75rem',
                            }}
                        >
                            <SelectedTicketLabel>
                                {t('modalPurchase.selectedTickets')}
                            </SelectedTicketLabel>
                            <SelectedTicketCard disabled={isDisabled}>
                                {tickets}
                            </SelectedTicketCard>
                            <SelectedTicketLabel>
                                {t('modalPurchase.freeCards')}:
                                <BuyModalValue
                                    style={{ marginLeft: '0.625rem' }}
                                >
                                    {freeTickets}
                                </BuyModalValue>
                            </SelectedTicketLabel>
                        </FlexColWrapper>
                        <FlexColWrapper style={{ width: '40%' }}>
                            <FlexRowWrapper
                                style={{
                                    width: '100%',
                                    marginBottom: '0.75rem',
                                }}
                            >
                                <DecrementButtonWrapper
                                    handleClick={decrement}
                                    disabled={$disabled || tickets === min}
                                />
                                <IncrementButtonWrapper
                                    handleClick={increment}
                                    disabled={$disabled || tickets === max}
                                />
                            </FlexRowWrapper>
                            <MaxButton
                                handleClick={maximize}
                                disabled={
                                    isDisabled ||
                                    tickets === max ||
                                    !hasIncrementalChange
                                }
                            >
                                {t('modalPurchase.btnMax')}
                            </MaxButton>
                            <ClearButton
                                handleClick={minimize}
                                disabled={
                                    isDisabled ||
                                    tickets === min ||
                                    !hasIncrementalChange
                                }
                            >
                                {t('modalPurchase.btnClear')}
                            </ClearButton>
                        </FlexColWrapper>
                    </FlexRowWrapper>
                    <FlexRowWrapper style={{ width: '55%' }}></FlexRowWrapper>
                </FlexColWrapper>
                <FlexColWrapper
                    style={{
                        width: '42%',
                        justifyContent: 'space-between',
                        height: '19rem',
                    }}
                >
                    {special ? (
                        <SpecialBannerWrapper text={special} />
                    ) : (
                        <SpecialBannerPlaceholder />
                    )}
                    <BuyButtonWrapper
                        isDisabled={isDisabled}
                        withTotal={true}
                        total={total}
                        handleClick={$onBuy}
                    />
                    <TicketInfoContainer>
                        <BuyModalLabel>
                            {t('modalPurchase.boughtCards')}:
                            <BuyModalValue style={{ marginLeft: '0.5rem' }}>
                                {boughtTickets - totalPromoCards}
                            </BuyModalValue>
                        </BuyModalLabel>
                        <BuyModalLabel>
                            {t('modalPurchase.freeCards')}:
                            <BuyModalValue style={{ marginLeft: '0.5rem' }}>
                                {boughtFreeTickets}
                            </BuyModalValue>
                        </BuyModalLabel>
                    </TicketInfoContainer>
                </FlexColWrapper>
            </FlexRowWrapper>
            <WageredLabelWrapper>
                <BuyModalLabel>
                    {t('modalPurchase.wagered')}:
                    <BuyModalValue style={{ marginLeft: '0.5rem' }}>
                        {wagered}
                    </BuyModalValue>
                </BuyModalLabel>
            </WageredLabelWrapper>
        </CommonModal>
    );
};
