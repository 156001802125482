import { FC } from 'react';
import { isMobile } from 'react-device-detect';
import styled from 'styled-components';
import { useState } from '@hookstate/core';
import { useTranslation } from 'react-i18next';
import { ToGoPanel } from '../../components/ToGoPanel';
import { useTogoMarkers } from '../../hooks/use-togo-markers';
import { useBoughtTickets } from '../../hooks/use-bought-tickets';
import { calls, current, meta } from '../../stores/backend';
import { chatMeta } from '../../stores/chat';

const TogoContainer = styled.div<{ chatOpen: boolean }>`
    display: flex;
    justify-content: center;
    align-items: center;

    margin-bottom: ${({ chatOpen }) =>
        isMobile ? (chatOpen ? 0 : '1.1875rem') : '0.5rem'};
`;

export const Togo: FC = () => {
    const { t } = useTranslation();

    const $calls = useState(calls);
    const $current = useState(current);
    const $meta = useState(meta);
    const $chatMeta = useState(chatMeta);

    const togos = useTogoMarkers($current, $calls, $meta);

    const boughtTickets = useBoughtTickets($current);

    if (boughtTickets === 0 || $current.game_mode.value !== 'GAME_MODE') {
        return null;
    }

    return (
        <TogoContainer chatOpen={$chatMeta.chatToggle.value}>
            {togos.map((togo, index) => {
                const LABEL = {
                    1: t('cardDeck.OneToGo'),
                    2: t('cardDeck.TwoToGo'),
                    3: t('cardDeck.ThreeToGo'),
                    4: t('cardDeck.FourOrMoreToGo'),
                };

                return (
                    <ToGoPanel
                        key={index}
                        label={LABEL[(index + 1) as keyof typeof LABEL]}
                        value={togo}
                    />
                );
            })}
        </TogoContainer>
    );
};
