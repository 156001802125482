import { FC } from 'react';
import { isMobile } from 'react-device-detect';
import styled, { css, useTheme } from 'styled-components';
import { useTranslation } from 'react-i18next';
import {
    CommonModal,
    CommonModalContainer,
    CommonModalProps,
} from '../CommonModal';

type GameParts = {
    description: string;
    prize: string;
    hasJackpot: boolean;
};

type GameInfoModalProps = CommonModalProps & {
    gameId: number;
    gameParts: GameParts[];
    gamePrize: string;
    hasJackpot: boolean;
    jackpotName?: string;
    jackpotCall?: number;
    jackpotPrize?: string;
    gameContinued?: boolean;
};

const commonStyle = css`
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
`;

const gameDetailsStyle = css`
    font-family: Signika-Bold;
    font-size: 2.5rem;
    font-weight: bold;
    margin-bottom: 3.188rem;
`;

const tableLabelStyle = css`
    font-family: Signika-Bold;
    font-size: 1.75rem;
    font-weight: bold;
    margin-bottom: 0.063rem;
    color: ${({ theme }) => theme.gameInfoModal.color.primary};
`;

const tableValueStyle = css`
    font-family: Signika-Medium;
    font-size: 1.875rem;
`;

const GameInfoModalContainer = styled(CommonModalContainer)`
    display: flex;
    flex-direction: column;
    width: 39.375rem;
    padding: 2.375rem 1.5rem;

    ${isMobile
        ? ''
        : `
        left: 16.5rem;
        margin-top: 5.25rem;
   `};
`;

const FlexWrapper = styled.div`
    display: flex;
    align-items: center;
`;

const GameDetailsText = styled.div`
    ${commonStyle};
    ${gameDetailsStyle};

    margin-right: 1.438rem;
    color: ${({ theme }) => theme.gameInfoModal.color.primary};
`;

const GameIDText = styled.div`
    ${commonStyle};
    ${gameDetailsStyle};

    color: ${({ theme }) => theme.gameInfoModal.color.secondary};
`;

const DescriptionLabel = styled.div`
    ${commonStyle};
    ${tableLabelStyle};

    flex-basis: 67%;
`;

const JackpotLabel = styled.div`
    ${commonStyle};
    ${tableLabelStyle};

    margin-right: 0.625rem;
    font-size: 1.5rem;
`;

const JackpotName = styled.div`
    ${commonStyle};
    ${tableValueStyle};

    margin-right: 0.625rem;
    font-size: 1.5rem;
    text-wrap: balance;
    color: ${({ theme }) => theme.gameInfoModal.color.secondary};
`;

const PrizeLabel = styled.div`
    ${commonStyle};
    ${tableLabelStyle};

    flex-basis: 35%;
`;

const Divider = styled.hr`
    width: 100%;
    border-width: 0;
    height: 0.063rem;
    margin: 1rem 0 1.313rem 0;
    background-color: ${({ theme }) => theme.gameInfoModal.background.divider};
`;

const NumberDescriptionContainer = styled.div`
    display: flex;
    align-items: center;
    flex-basis: 67%;
`;

const PartNumber = styled.div`
    ${commonStyle};
    font-family: Signika-Bold;
    font-size: 1.75rem;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 2.625rem;
    height: 2.625rem;
    margin-right: 1.313rem;
    border-radius: 50%;

    ${({ theme: { gameInfoModal } }) => `
        color: ${gameInfoModal.color.primary};
        background-color: ${gameInfoModal.background.number};
    `};
`;

const DescriptionValue = styled.div`
    ${commonStyle};
    ${tableValueStyle};

    color: ${({ theme }) => theme.gameInfoModal.color.secondary};
`;

const PrizeValue = styled.div`
    ${commonStyle};
    ${tableValueStyle};

    color: ${({ theme }) => theme.gameInfoModal.color.primary};
`;

const JackpotContainer = styled.div`
    display: flex;
    margin-left: 3.75rem;
    margin-top: 0.938rem;
    white-space: nowrap;
`;

const GameContinuedText = styled.div`
    font-family: Signika-SemiBold;
    font-size: 1.125rem;
    color: ${({ theme }) => theme.gameInfoModal.color.secondary};
`;

export const GameContinuedIcon = styled.img`
    width: 2.5rem;
    height: 2.5rem;
    margin-left: 1rem;
`;

export const GameInfoModal: FC<GameInfoModalProps> = ({
    gameId,
    gameParts,
    gamePrize,
    hasJackpot,
    jackpotName,
    jackpotCall,
    jackpotPrize,
    gameContinued,
    ...props
}) => {
    const { t } = useTranslation();
    const theme = useTheme();

    const { starImage4 } = theme.images;

    return (
        <CommonModal {...props} Container={GameInfoModalContainer}>
            <FlexWrapper>
                <GameDetailsText>{t('infoBox.gameDetails')}</GameDetailsText>
                <GameIDText>
                    {t('infoBox.gameDetailsSID', { sid: gameId })}
                </GameIDText>
            </FlexWrapper>
            <FlexWrapper>
                <DescriptionLabel>
                    {t('infoBox.gameDetailsPartDescription')}
                </DescriptionLabel>
                <PrizeLabel>{t('infoBox.gameDetailsPrize')}</PrizeLabel>
            </FlexWrapper>
            <Divider />
            {gameParts.map((part, index) => {
                return (
                    <div key={index}>
                        <FlexWrapper>
                            <NumberDescriptionContainer>
                                <PartNumber>{index + 1}</PartNumber>
                                <DescriptionValue>
                                    {part.description}
                                </DescriptionValue>
                                {gameContinued && (
                                    <GameContinuedIcon
                                        src={starImage4}
                                        alt="Continued Game Icon"
                                    />
                                )}
                            </NumberDescriptionContainer>
                            <PrizeValue>{gamePrize}</PrizeValue>
                        </FlexWrapper>
                        <JackpotContainer>
                            {part.hasJackpot && hasJackpot ? (
                                <>
                                    <JackpotLabel>
                                        {`${t('infoBox.gameDetailsJackpot')}:`}
                                    </JackpotLabel>
                                    <JackpotName>
                                        {t('infoBox.jackpotFormatting', {
                                            jpName: jackpotName,
                                            jpCall: jackpotCall,
                                        }) +
                                            ' ' +
                                            jackpotPrize}
                                    </JackpotName>
                                </>
                            ) : null}
                        </JackpotContainer>
                        <Divider />
                    </div>
                );
            })}
            {gameContinued && (
                <GameContinuedText>
                    {t('infoBox.gameContinued')}
                </GameContinuedText>
            )}
        </CommonModal>
    );
};
