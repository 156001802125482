import { DefaultTheme } from 'styled-components';
import arrowLeft from '../assets/images/themes/common/arrow-left/arrow-left.png';
import starBurst from '../assets/images/themes/common/star-burst/starbur1.png';
import starBurstJackpot from '../assets/images/themes/common/star-burst/starburst2.png';
import starBurstCover from '../assets/images/themes/common/star-burst/starburstcover.png';
import ballType75 from '../assets/images/themes/common/ball-type/75.png';
import ballType80 from '../assets/images/themes/common/ball-type/80.png';
import ballType90 from '../assets/images/themes/common/ball-type/90.png';
import infoButton from '../assets/images/themes/common/info-button/btn-info.png';
import prebuyButton from '../assets/images/themes/common/prebuy-button/prebuy.png';
import players from '../assets/images/themes/common/primary-panel-card/players.png';
import cards from '../assets/images/themes/common/primary-panel-card/cards.png';
import starImage from '../assets/images/themes/common/star/star.png';
import starImage2 from '../assets/images/themes/common/star/star2.png';
import starImage3 from '../assets/images/themes/common/star/star3.png';
import starImage4 from '../assets/images/themes/common/star/star4.png';
import starImage5 from '../assets/images/themes/common/star/star5.png';
import ball80Overlay from '../assets/images/themes/common/ball80/ball80_overlay@1x.png';
import salesClosed from '../assets/images/themes/common/sales-closed/sales-closed.png';
import gameCancelled from '../assets/images/themes/common/game-cancelled/game-cancelled.png';
import convert from '../assets/images/themes/common/convert/convert.png';
import cart from '../assets/images/themes/common/cart/cart.png';
import freeCards from '../assets/images/themes/common/free-cards/freecards.png';
import newCards from '../assets/images/themes/common/new-cards/newcards.png';
import headerBingo from '../assets/images/themes/common/bingo/header-bingo.png';
import pickCards from '../assets/images/themes/common/pick-cards/pickcards.png';
import nextGame from '../assets/images/themes/common/next-game/next-game.png';
import moneybag from '../assets/images/themes/common/moneybag/moneybag.png';
import closeButton from '../assets/images/themes/common/close-button/close-button.png';
import emoticon from '../assets/images/themes/common/emoticon/emoticon.png';
import send from '../assets/images/themes/common/send/send.png';
import users from '../assets/images/themes/common/users/users.png';
import link from '../assets/images/themes/common/link/link.png';
import chatroom from '../assets/images/themes/common/chatroom/chatroom.png';
import messageBubble from '../assets/images/themes/common/message-bubble/message-bub.png';
import maxButton from '../assets/images/themes/common/max-min-button/max-button.png';
import minButton from '../assets/images/themes/common/max-min-button/min-button.png';
import placeholder from '../assets/images/themes/common/placeholder/placeholder.png';
import arrowLeftFooter from '../assets/images/themes/common/arrow-left/arrow-left2.png';
import loading from '../assets/images/themes/common/loading/loading.gif';
import rotateScreen from '../assets/images/themes/common/rotate-screen/rotate-screen.png';
import starGlow from '../assets/images/themes/common/star-glow/star-glow.gif';
import moreButton from '../assets/images/themes/default/more-button/more-btn.png';
import countdownTimer from '../assets/images/themes/default/countdown-timer/countdown-timer.png';
import arrowDown from '../assets/images/themes/default/arrow-down/arrow-down.png';
import Emojis from '../emojis';
import DauberStyles from '../dauberStyles';

const defaultTheme: DefaultTheme = {
    bodyBackground: {
        gradientTop: '#0961ff',
        gradientBottom: '#56d2fe',
    },
    color: '#fff',
    images: {
        arrowLeft,
        arrowLeftFooter,
        starBurst,
        starBurstJackpot,
        starBurstCover,
        ballType75,
        ballType80,
        ballType90,
        infoButton,
        moreButton,
        prebuyButton,
        players,
        cards,
        starImage,
        starImage2,
        starImage3,
        starImage4,
        starImage5,
        ball80Overlay,
        salesClosed,
        gameCancelled,
        countdownTimer,
        convert,
        cart,
        freeCards,
        newCards,
        headerBingo,
        pickCards,
        nextGame,
        moneybag,
        closeButton,
        emoticon,
        send,
        arrowDown,
        users,
        link,
        chatroom,
        messageBubble,
        maxButton,
        minButton,
        placeholder,
        loading,
        rotateScreen,
        starGlow,
        customEmoji: [
            { image: Emojis.B, keyCode: ':b:' },
            { image: Emojis.I, keyCode: ':i:' },
            { image: Emojis.N, keyCode: ':n:' },
            { image: Emojis.G, keyCode: ':g:' },
            { image: Emojis.O, keyCode: ':o:' },
            { image: Emojis.HardLaugh, keyCode: ':0' },
            { image: Emojis.Angry, keyCode: ':@' },
            { image: Emojis.BrokenHeart, keyCode: '(u)' },
            { image: Emojis.Confused, keyCode: ':s' },
            { image: Emojis.Devious, keyCode: ';d' },
            { image: Emojis.Embarrased, keyCode: ':$' },
            { image: Emojis.Geek, keyCode: '8-|' },
            { image: Emojis.Guilty, keyCode: ':#' },
            { image: Emojis.Happy, keyCode: ':)' },
            { image: Emojis.InLove, keyCode: 'x-)' },
            { image: Emojis.Kiss, keyCode: '(k)' },
            { image: Emojis.Lunch, keyCode: '(pi)' },
            { image: Emojis.PalmTree, keyCode: '(ip)' },
            { image: Emojis.Sarcastic, keyCode: ';s' },
            { image: Emojis.Sick, keyCode: '+o(' },
            { image: Emojis.Surprised, keyCode: ':-o' },
            { image: Emojis.Tongue, keyCode: ':p' },
            { image: Emojis.VeryAngry, keyCode: 'x@' },
            { image: Emojis.VeryHappy, keyCode: '|d' },
            { image: Emojis.Wink, keyCode: ';)' },
            { image: Emojis.Wondering, keyCode: ':?' },
            { image: Emojis.Worried, keyCode: ':/' },
            { image: Emojis.cake, keyCode: '(^)' },
            { image: Emojis.clock, keyCode: '(c)' },
            { image: Emojis.cool, keyCode: '(h)' },
            { image: Emojis.crying, keyCode: '(cry)' },
            { image: Emojis.devil, keyCode: '(6)' },
            { image: Emojis.flower, keyCode: '(f)' },
            { image: Emojis.heart, keyCode: '(l)' },
            { image: Emojis.moon, keyCode: '(s)' },
            { image: Emojis.present, keyCode: '(gi)' },
            { image: Emojis.star, keyCode: '(*)' },
            { image: Emojis.sun, keyCode: '(#)' },
        ],
        dauberStyle: {
            A: DauberStyles.A,
            B: DauberStyles.B,
            C: DauberStyles.C,
            D: DauberStyles.D,
            E: DauberStyles.E,
            F: DauberStyles.F,
            G: DauberStyles.G,
            H: DauberStyles.H,
            I: DauberStyles.I,
            J: DauberStyles.J,
            K: DauberStyles.K,
            L: DauberStyles.L,
            M: DauberStyles.M,
            N: DauberStyles.N,
            O: DauberStyles.O,
            P: DauberStyles.P,
            Q: DauberStyles.Q,
            R: DauberStyles.R,
            S: DauberStyles.S,
            T: DauberStyles.T,
        },
    },
    dauberColor: {
        A: '#0247fe',
        B: '#3b02fe',
        C: '#b902fe',
        D: '#fe02c4',
        E: '#fe0245',
        F: '#fe3d02',
        G: '#febb02',
        H: '#c2fe02',
        I: '#55d827',
        J: '#00b100',
        K: '#11edb4',
        L: '#00bffe',
        M: '#c06f41',
        N: '#808080',
        O: '#000000',
    },
    customEmojiData: [
        {
            id: 'bingo',
            name: 'Bingo',
            emojis: [
                {
                    id: 'b',
                    name: 'B',
                    keywords: ['(b)', 'bingo', 'ball', 'B'],
                    skins: [
                        {
                            src: Emojis.B,
                        },
                    ],
                },
                {
                    id: 'i',
                    name: 'I',
                    keywords: ['(i)', 'bingo', 'ball', 'I'],
                    skins: [
                        {
                            src: Emojis.I,
                        },
                    ],
                },
                {
                    id: 'n',
                    name: 'N',
                    keywords: ['(n)', 'bingo', 'ball', 'N'],
                    skins: [
                        {
                            src: Emojis.N,
                        },
                    ],
                },
                {
                    id: 'g',
                    name: 'G',
                    keywords: ['(g)', 'bingo', 'ball', 'G'],
                    skins: [
                        {
                            src: Emojis.G,
                        },
                    ],
                },
                {
                    id: 'o',
                    name: 'O',
                    keywords: ['(o)', 'bingo', 'ball', 'O'],
                    skins: [
                        {
                            src: Emojis.O,
                        },
                    ],
                },
            ],
        },
    ],
    gameId: {
        color: '#fff',
        textShadow: 'rgba(0, 0, 0, 0.11)',
    },
    credit: {
        color: '#fff',
        headBorder: {
            cash: '#4b2900',
            bonus: '#47004b',
            total: '#00434b',
        },
        headBoxShadow: {
            cash: '#ffed2e',
            bonus: '#ff69f4',
            total: '#69fffc',
        },
        headBackground: {
            cash: {
                gradientTop: '#d98d05',
                gradientBottom: '#f6c31e',
            },
            bonus: {
                gradientTop: '#a00ac3',
                gradientBottom: '#e550f1',
            },
            total: {
                gradientTop: '#0aaec3',
                gradientBottom: '#50def1',
            },
        },
        bodyBackground: {
            gradientTop: '#020837',
            gradientBottom: '#364dd7',
        },
    },
    tabHeader: {
        color: '#fff',
        borderBottom: '#fff',
    },
    commonPanel: {
        boxShadow: {
            secondary: '#250fb6',
        },
        border: {
            primary: '#2e9ffe',
            secondary: '#77d3ff',
        },
        background: {
            primary: {
                gradientTop: '#2c03b9',
                gradientBottom: '#4269ff',
            },
            secondary: {
                gradientTop: '#130761',
                gradientBottom: '#41148b',
            },
        },
    },
    primaryPanel: {
        color: {
            label: '#fff',
            value: '#ffd000',
            divider: '#3057e0',
        },
    },
    playersCards: {
        boxShadow: '#250fb6',
        background: {
            gradientTop: '#130761',
            gradientBottom: '#41148b',
        },
        text: {
            color: '#ffd000',
        },
    },
    goodluckPanel: {
        color: {
            upperText: '#fff',
            lowerText: '#ffd000',
        },
    },
    toGoPanel: {
        color: '#fff',
        border: '#50c9ff',
        background: {
            orange: '#ff8e10',
            green: '#159b13',
            pink: '#f2268e',
            blue: '#0289e5',
        },
    },
    timerPanel: {
        color: {
            timer: '#ff110b',
            upperText: '#fff',
            lowerText: '#ffd000',
        },
    },
    cardTogo: {
        color: '#fff',
        textShadow: '#250c61',
    },
    cardId: {
        color: '#fff',
    },
    cardWinner: {
        color: '#fff',
        textShadow: '#250c61',
        gradient: {
            top: '#ffde63',
            middle: '#ffc900',
            bottom: '#ff9003',
        },
    },
    congratulationsPanel: {
        upperText: {
            gradientTop: '#ffde63',
            gradientMiddle: '#ffc900',
            gradientBottom: '#ff9003',
        },
        middleText: {
            color: '#fff',
        },
        lowerText: {
            color: '#33dffb',
        },
    },
    cardNumber75: {
        color: {
            isCalled: '#fff',
            normal: '#250c61',
        },
        background: '#fff',
        border: '#4b1b00',
        boxShadow: {
            upper: '#ff7a20',
            lower: 'rgba(234, 110, 14, 0.28)',
        },
        gradient: {
            top: '#f24600',
            bottom: '#c11f00',
        },
    },
    cardNumber90: {
        color: {
            isCalled: '#fff',
            normal: '#250c61',
        },
        background: '#fff',
        border: '#4b1b00',
        boxShadow: {
            upper: '#ff7a20',
            lower: 'rgba(234, 110, 14, 0.28)',
        },
        gradient: {
            top: '#f24600',
            bottom: '#c11f00',
        },
    },
    cardNumber80: {
        color: {
            isCalled: '#fff',
            normal: '#140f31',
        },
        border: 'rgba(0, 0, 0, 0.4)',
        background: {
            red: {
                normal: {
                    background: '#ff5757',
                    boxShadow: 'inset 0 0.063rem 0.313rem 0 #820000',
                },
                isCalled: {
                    background: '#d10101',
                    boxShadow:
                        'inset 0.188rem 0.188rem 0 0 #fd4747, inset -0.188rem -0.188rem 0 0 #c00202',
                },
            },
            yellow: {
                normal: {
                    background: '#ffd000',
                    boxShadow: 'inset 0 0.063rem 0.313rem 0 #820000',
                },
                isCalled: {
                    background: '#f99a02',
                    boxShadow:
                        'inset 0.188rem 0.188rem 0 0 #f9ac02, inset -0.188rem -0.188rem 0 0 #e77a02',
                },
            },
            blue: {
                normal: {
                    background: '#00cdff',
                    boxShadow: 'inset 0 0.063rem 0.313rem 0 #820000',
                },
                isCalled: {
                    background: '#00b1ff',
                    boxShadow:
                        'inset 0.188rem 0.188rem 0 0 #11c6ff, inset -0.188rem -0.188rem 0 0 #019ce0',
                },
            },
            gray: {
                normal: {
                    background: '#dbdbdb',
                    boxShadow: 'inset 0 0.063rem 0.313rem 0 #820000',
                },
                isCalled: {
                    background: '#d1d4d6',
                    boxShadow:
                        'inset 0.188rem 0.188rem 0 0 #eaeced, inset -0.188rem -0.188rem 0 0 #c2c5c7',
                },
            },
        },
        stickBackground: {
            background: 'linear-gradient(to bottom, #a51111, #250101)',
            boxShadow: 'inset 0.188rem 0.25rem 0 0 rgba(255, 255, 255, 0.4)',
        },
    },
    cardText: {
        color: '#fff',
        textShadow: '#000',
    },
    callsCard: {
        color: {
            text: '#fff',
            number: '#ffd000',
        },
        ballColorBorder: '#fff',
        nextCalls: {
            blue: '#0522bd',
            red: '#9c0000',
            purple: '#620a7e',
            green: '#1e8600',
            yellow: '#d29304',
            transparent: 'transparent',
        },
    },
    commonCard: {
        boxShadow: {
            first: {
                default: '#136ad7',
                winner: '#c01601',
                oneTG: '#ce6f05',
                twoTG: '#0b7709',
                threeTG: '#db0c76',
            },
            second: 'rgba(0, 0, 0, 0.3)',
            third: {
                default: '#80ccff',
                winner: '#ff533e',
                oneTG: '#ffb110',
                twoTG: '#25c422',
                threeTG: '#ff6dcf',
            },
        },
        background: {
            default: '#0289e5',
            winner: '#e61c04',
            oneTG: '#ff8e10',
            twoTG: '#159b13',
            threeTG: '#f2268e',
        },
    },
    callBoard: {
        color: {
            text: '#fff',
            number: '#0c1085',
        },
        board75: {
            background: '#fff',
        },
        board90: {
            color: '#fff',
            background: '#250c61',
        },
        board80: {
            color: '#000',
            background: ['#ed0606', '#f9b202', '#00b1ff', '#d1d4d6'],
        },
    },
    callBoardText: {
        background: {
            B: '#2495ed',
            I: '#f04141',
            N: '#af51be',
            G: '#22ba1f',
            O: '#f2b92e',
        },
    },
    ballHole: {
        boxShadow: {
            upper: '#1587ed',
            mid: 'rgba(0, 0, 0, 0.5)',
            lower: '#1587ed',
        },
        border: '#2ecafe',
        backgroundGradient: {
            upper: '#3640d7',
            lower: '#020837',
        },
    },
    ballTray: {
        boxShadow: {
            first: '#1587ed',
            second: 'rgba(0, 0, 0, 0.5)',
        },
        border: '#2ecafe',
        background: {
            gradientTop: '#00072e',
            gradientMiddle: '#1848e5',
            gradientBottom: '#020837',
        },
    },
    salesClosedModal: {
        border: '#77d3ff',
        boxShadow: {
            first: 'rgba(0, 0, 0, 0.5)',
            second: '#250fb6',
        },
        backgroundGradient: {
            upper: '#130761',
            lower: '#41148b',
        },
        color: {
            heading: '#fff',
            subheading: '#ffd000',
        },
    },
    ticketsPurchasedPanel: {
        color: {
            timer: '#ff110b',
            label: '#fff',
            value: '#ffd000',
        },
    },
    closeButtonModal: {
        color: '#fff',
        boxShadow: {
            first: '#ea652a',
            second: 'rgba(255, 58, 58, 0.39)',
        },
        border: 'rgba(37, 15, 182, 0.6)',
        backgroundGradient: {
            upper: '#e31500',
            lower: '#c00101',
        },
    },
    commonModal: {
        border: '#2f99ff',
        background: '#2f99ff',
        boxShadow: '#250fb6',
        color: {
            title: 'gold',
            content: '#fff',
        },
        backgroundGradient: {
            upper: '#130761',
            lower: '#41148b',
        },
    },
    dauberCommonModal: {
        background: '#fcfdff',
        closeButton: {
            color: '#4b486a',
        },
    },
    dauberModal: {
        title: {
            color: '#351080',
        },
        background: '#fcfdff',
        border: '#fcfdff',
        scroll: {
            track: '#f0eded',
            thumb: '#e3e4e8',
        },
    },
    settingsMenuButton: {
        color: '#fff',
        border: '#003d4b',
        boxShadow: {
            first: '#1afffd',
            second: 'rgba(14, 223, 234, 0.4)',
        },
        backgroundGradient: {
            upper: '#25d1ff',
            lower: '#3295fd',
        },
    },
    countdownModal: {
        color: '#ffd000',
    },
    currencyToggle: {
        color: '#fff',
        border: '#4b2900',
        background: '#c45900',
        textBoxShadow: '#661e00',
        boxShadow: {
            first: '#ffed2e',
            second: 'rgba(255, 200, 38, 0.28)',
        },
        backgroundGradient: {
            upper: '#f6c31e',
            lower: '#d98d05',
        },
    },
    commonToggle: {
        background: {
            slider: '#ccc',
            input: '#4dd865',
        },
        boxShadow: {
            slider: 'rgba(0, 0, 0, 0.17)',
            input: '#4dd865',
        },
    },
    modalTitle: {
        backgroundGradient: {
            upper: '#ffc900',
            lower: '#ff9003',
        },
    },
    settingsModal: {
        color: {
            label: '#fff',
            version: '#7c4fc8',
        },
        backgroundGradient: {
            upper: 'rgba(19, 7, 97, 0.32)',
            lower: 'rgba(65, 20, 139, 0.32)',
        },
    },
    modalLabel: {
        color: '#fff',
    },
    incrementButton: {
        color: '#fff',
        boxShadow: {
            first: '#1afffd',
            second: 'rgba(14, 223, 234, 0.4)',
        },
        border: '#003d4b',
        backgroundGradient: {
            upper: '#25d1ff',
            lower: '#3295fd',
        },
    },
    plusMinusInput: {
        color: '#ffd000',
        border: '#5141ad',
        background: '#573eab',
        boxShadow: {
            first: 'rgba(19, 2, 76, 0.6)',
            second: 'rgba(35, 12, 111, 0.28)',
        },
    },
    checkbox: {
        border: '#5141ad',
        background: {
            container: '#573eab',
            content: '#ffd000',
        },
        boxShadow: {
            first: 'rgba(19, 2, 76, 0.6)',
            second: 'rgba(35, 12, 111, 0.28)',
        },
    },
    commonButton: {
        color: '#fff',
        border: '#004b48',
        backgroundGradient: {
            upper: '#a5d12f',
            lower: '#1bb604',
        },
        boxShadow: {
            first: '#d0fc5a',
            second: 'rgba(136, 255, 23, 0.41)',
        },
    },
    autobuyModal: {
        color: {
            message: '#fff',
            divider: '#2f99ff',
        },
    },
    timer: {
        color: '#ff110b',
    },
    buyButton: {
        color: {
            default: '#fff',
            totalValue: '#ffe900',
        },
        enabled: {
            border: '#4b1f00',
            background: {
                divider1: '#f6b503',
                divider2: '#cb7200',
            },
            boxShadow: {
                first: 'rgba(0, 0, 0, 0.3)',
                second: '#eae70e',
                third: 'rgba(234, 37, 14, 0.28)',
            },
            backgroundGradient: {
                upper: '#f6b703',
                lower: '#ec7600',
            },
        },
        disabled: {
            border: '#606060',
            background: {
                divider1: '#efeeee',
                divider2: '#6c6c6c',
            },
            boxShadow: {
                first: 'rgba(0, 0, 0, 0.3)',
                second: '#efeeee',
                third: 'rgba(0, 0, 0, 0.28)',
            },
            backgroundGradient: {
                upper: '#d4d4d4',
                lower: '#6c6c6c',
            },
        },
    },
    buyModal: {
        color: {
            label: '#fff',
            value: '#ffd000',
            jackpot: '#72a8e0',
        },
        cards: {
            border: '#5141ad',
            background: '#573eab',
            boxShadow: {
                first: 'rgba(35, 12, 111, 0.28)',
                second: 'rgba(19, 2, 76, 0.6)',
            },
        },
        autobuy: {
            color: '#fff',
            border: '#003d4b',
            boxShadow: {
                first: '#1afffd',
                second: 'rgba(14, 223, 234, 0.4)',
            },
            backgroundGradient: {
                upper: '#25d1ff',
                lower: '#3295fd',
            },
        },
        circle: {
            background: {
                active: '#29ff00',
                inactive: '#e61c04',
            },
            boxShadow: {
                active: {
                    first: '#e0ff4b',
                    second: '#25e101',
                },
                inactive: {
                    first: '#ff4b4b',
                    second: '#c90000',
                },
            },
        },
        clear: {
            boxShadow: {
                first: '#8c929c',
                second: 'rgba(166, 166, 166, 0.4)',
            },
            backgroundGradient: {
                upper: '#80879b',
                lower: '#656d78',
            },
        },
        inputBorder: '#33c4fb',
    },
    commonBall: {
        boxShadow: 'rgba(0, 0, 0, 0.2)',
    },
    ball75: {
        color: '#250c61',
        background: '#fff',
        border: '#fff',
        blue: {
            color: '#0281e7',
            border: '#005dc4',
            backgroundGradient: {
                first: '#fff',
                second: '#00ccff',
                third: '#0522bd',
                fourth: '#00ccff',
            },
        },
        red: {
            color: '#fa0900',
            border: '#c40000',
            backgroundGradient: {
                first: '#fff',
                second: '#ff4c4c',
                third: '#9c0000',
                fourth: '#ff4c4c',
            },
        },
        purple: {
            color: '#b700e9',
            border: '#7500c4',
            backgroundGradient: {
                first: '#fff',
                second: '#e175ee',
                third: '#620a7e',
                fourth: '#e175ee',
            },
        },
        green: {
            color: '#1cc80c',
            border: '#27c400',
            backgroundGradient: {
                first: '#fff',
                second: '#3eff25',
                third: '#1e8600',
                fourth: '#3eff25',
            },
        },
        yellow: {
            color: '#e3ab02',
            border: '#c48500',
            backgroundGradient: {
                first: '#fff',
                second: '#ffeb8b',
                third: '#d29304',
                fourth: '#ffeb8b',
            },
        },
    },
    ball80: {
        color: '#250c61',
        background: '#fff',
        red: {
            border: '#c40000',
            backgroundGradient: {
                first: '#fff',
                second: '#ff4c4c',
                third: '#9c0000',
                fourth: '#ff4c4c',
            },
        },
        yellow: {
            border: '#c48500',
            backgroundGradient: {
                first: '#fff',
                second: '#ffeb8b',
                third: '#d29304',
                fourth: '#ffeb8b',
            },
        },
        blue: {
            border: '#005dc4',
            backgroundGradient: {
                first: '#fff',
                second: '#51e3ff',
                third: '#0522bd',
                fourth: '#51e3ff',
            },
        },
        gray: {
            border: '#939393',
            backgroundGradient: {
                first: '#fff',
                second: '#d4d4d4',
                third: '#6c6c6c',
                fourth: '#d4d4d4',
            },
        },
    },
    ball90: {
        color: '#250c61',
        background: '#fff',
        border: '#939393',
        backgroundGradient: {
            first: '#fff',
            second: '#d4d4d4',
            third: '#6c6c6c',
            fourth: '#d4d4d4',
        },
    },
    footer: {
        border: '#003d4b',
        boxShadow: {
            first: '#1afffd',
            second: 'rgba(14, 223, 234, 0.4)',
        },
        backgroundGradient: {
            upper: '#25d1ff',
            lower: '#3295fd',
        },
    },
    bingoCardPatter: {
        filled: '#ff0700',
        notFilled: '#f0f3f6',
    },
    pickCardsModal: {
        color: '#ff9200',
        scroll: {
            track: 'rgba(240, 237, 237, 0.35)',
            thumb: 'rgba(255, 255, 255, 0.5)',
        },
        border: '#004b48',
        backgroundGradient: {
            upper: '#a5d12f',
            lower: '#1bb604',
        },
        boxShadow: {
            first: 'rgba(0, 0, 0, 0.3)',
            second: '#d0fc5a',
            third: 'rgba(136, 255, 23, 0.41)',
        },
    },
    winnerModal: {
        borderColor: '#e31500',
        boxShadow: '#000',
        background: 'rgba(44, 0, 76, 0.46)',
        backgroundGradient: {
            upper: '#3603ac',
            lower: '#8b15fd',
        },
        scroll: {
            track: 'rgba(255, 255, 255, 0.2)',
            thumb: '#924cfe',
        },
        title: {
            color: '#ffd000',
            textShadow: {
                first: '#fff',
                second: '#250c61',
                third: '#250c61',
            },
            backgroundGradient: {
                upper: '#ffde63',
                middle: '#ffc900',
                lower: '#ff9003',
            },
        },
    },
    specialBanner: {
        color: '#fff',
        border: '#5141ad',
        background: '#573eab',
        boxShadow: {
            first: 'rgba(19, 2, 76, 0.6)',
            second: 'rgba(35, 12, 111, 0.28)',
        },
    },
    pickedCards: {
        background: {
            picked: '#e61c04',
            bought: '#008000',
        },
        boxShadow: {
            picked: {
                first: '#c01601',
                second: '#ff533e',
            },
            bought: {
                first: '#045d04',
                second: '#5aaa5a',
            },
        },
    },
    footerSG: {
        boxShadow: {
            first: '#5f28dd',
            second: '#250fb6',
        },
        backgroundGradient: {
            upper: '#5925c1',
            lower: '#130761',
        },
    },
    footerTitle: {
        color: '#ffd000',
        textShadow: '#250c61',
    },
    gameInfoModal: {
        color: {
            primary: '#fff',
            secondary: '#ffd000',
        },
        background: {
            number: '#ff110b',
            divider: '#2f99ff',
        },
    },
    nextGameSG: {
        color: '#ffd000',
        border: '#5141ad',
        background: '#573eab',
        boxShadow: {
            first: 'rgba(19, 2, 76, 0.6)',
            second: 'rgba(35, 12, 111, 0.28)',
        },
    },
    jackpotModal: {
        color: '#ffd000',
        background: 'rgba(24, 0, 76, 0.8)',
    },
    chatMain: {
        background: '#fcfdff',
        border: '#fcfdff',
        scroll: {
            track: '#f0eded',
            thumb: '#e3e4e8',
        },
        color: {
            welcome: '#939393',
            mentions: '#006aff',
            master: {
                name: '#ed1c24',
                message: '#ed1c24',
            },
            normal: {
                name: '#006aff',
                message: '#0d0925',
            },
            system: {
                message: '#d3d3d3',
            },
        },
    },
    chatHeader: {
        border: '#ff9302',
        boxShadow: {
            container: '#f89b0c',
            close: 'rgba(255, 228, 107, 0.35)',
            content: '#ffd000',
        },
        backgroundGradient: {
            container: {
                upper: '#e84203',
                lower: '#f89c0c',
            },
            content: {
                upper: '#ff6f04',
                lower: '#f0bc33',
            },
        },
    },
    gamePart: {
        border: '#3e54f1',
        background: {
            active: '#29ff00',
            inactive: 'rgba(255, 255, 255, 0.2)',
        },
        boxShadow: {
            first: {
                active: '#e0ff4b',
                inactive: 'rgba(19, 2, 76, 0.6)',
            },
            second: '#230c6f',
        },
    },
    chatUsers: {
        border: {
            scroll: '#fcfdff',
            textField: '#e5e8ef',
        },
        color: {
            title: '#4b486a',
            button: '#fff',
            label: '#0d0925',
            mods: '#ed1c24',
            users: '#8936ff',
        },
        scroll: {
            track: '#f0eded',
            thumb: '#e3e4e8',
        },
        background: {
            container: '#fcfdff',
            title: '#f2f2f2',
            button: '#f69c20',
            list: '#fcfdff',
        },
    },
    chatRooms: {
        background: {
            container: '#615f75',
            currentRoom: '#eef0f4',
            icon: '#29ff00',
        },
        color: {
            currentRoom: '#4b486a',
            button: '#fff',
        },
        border: {
            currentRoom: '#eb5205',
            roomItem: 'rgba(255, 255, 255, 0.1)',
            button: '#0044ac',
        },
        boxShadow: {
            icon: '#e0ff4b',
            button: {
                first: '#1afffd',
                second: 'rgba(14, 223, 234, 0.4)',
            },
        },
        backgroundGradient: {
            upper: '#25d1ff',
            lower: '#3295fd',
        },
    },
    notification: {
        color: '#fff',
        background: '#e61c04',
    },
    emoji: {
        border: '#2e9ffe',
    },
    username: {
        color: '#fff',
        background: '#364dd7',
    },
    cardList: {
        background: 'rgba(255, 255, 255, 0.25)',
    },
    partWinnerModal: {
        background: 'rgba(114, 168, 224, 0.2)',
    },
    chatSendButton: {
        border: '#004b48',
        backgroundGradient: {
            upper: '#a5d12f',
            lower: '#1bb604',
        },
        boxShadow: {
            first: '#d0fc5a',
            second: 'rgba(136, 255, 23, 0.41)',
        },
    },
    sideGames: {
        backgroundGradient: {
            upper: '#0055c4',
            middle: '#5c96e2',
            lower: '#0055c4',
        },
    },
};

export default defaultTheme;
