import { FC } from 'react';
import styled, { useTheme } from 'styled-components';
import { useTranslation } from 'react-i18next';
import { CommonPanel } from '../CommonPanel';
import { InfoButton } from '../InfoButton';
import { BuyButton } from '../BuyButton';
import { PlayersCards } from '../PlayersCards';
import { PrimaryPanelText } from '../PrimaryPanelText';
import { GamePart } from '../GamePart';
import { GameType } from '../GameType';
import { BingoCardPattern } from '../BingoCardPattern';
import { CurrentGamePanelProps } from './mobile';

const CurrentGamePanelContainer = styled(CommonPanel)`
    position: relative;
    height: auto;
    margin-bottom: 0.5rem;
    width: 89.8%;
`;

export const CurrentGamePart = styled(GamePart)`
    width: 100%;
    justify-content: flex-end;
`;

const UpperContainer = styled.div`
    display: flex;
    justify-content: flex-start;
    align-items: center;
    width: 100%;
    margin-bottom: 0.5rem;
`;

const UpperTextContainer = styled.div`
    display: flex;
    flex-direction: column;
    flex-basis: 71.5%;
    margin-right: 1rem;
`;

const UpperImageContainer = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-basis: 14%;
`;

const Divider = styled.hr`
    width: 100%;
    margin-left: 0;
    border-width: 0;
    height: 0.125rem;
    background-color: ${({ theme: { primaryPanel } }) =>
        primaryPanel.color.divider};
`;

const LowerContainer = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 87%;
`;

const LowerTextContainer = styled.div<{ gameType: number }>`
    display: flex;
    justify-content: center;
    flex-direction: column;
`;

const FlexRowWrapper = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
`;

export const FlexColWrapper = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    row-gap: 0.25rem;
`;

export const PlayersCardsWrapper = styled(PlayersCards)`
    height: 2.5rem;
`;

const BuyButtonWrapper = styled(BuyButton)`
    position: absolute;
    top: 0;
    right: -7rem;
    bottom: 0;
    margin: auto 0;
`;

export const GameContinuedIcon = styled.img`
    width: 2rem;
    height: 2rem;
    align-self: flex-end;
`;

export const CurrentGamePanelDesktop: FC<CurrentGamePanelProps> = ({
    seconds,
    gameTitle,
    patternName,
    gamePrize,
    gamePlayers,
    gameCards,
    hasJackpot,
    jackpotName,
    jackpotCall,
    jackpotPrize,
    currentPart,
    gameParts,
    gameType,
    patternType,
    parts,
    gameLinked,
    gameContinued,
    onInfoBox,
    onBuy,
    ...props
}) => {
    const { t } = useTranslation();
    const theme = useTheme();

    const { starImage4 } = theme.images;
    const CURRENT_GAME = t('infoBox.currentGame');

    return (
        <CurrentGamePanelContainer {...props} type="primary">
            <UpperContainer>
                <UpperTextContainer>
                    <PrimaryPanelText
                        label={CURRENT_GAME}
                        value={gameTitle}
                        type="big"
                        gameLinked={gameLinked}
                    />
                    <Divider />
                </UpperTextContainer>
                <UpperImageContainer>
                    <InfoButton handleClick={onInfoBox} />
                    <GameType gameType={gameType} />
                </UpperImageContainer>
            </UpperContainer>
            <LowerContainer>
                <LowerTextContainer gameType={gameType}>
                    <PrimaryPanelText
                        label={patternName}
                        value={gamePrize}
                        type="small"
                    />
                    {hasJackpot && (
                        <PrimaryPanelText
                            label={t('infoBox.jackpotFormatting', {
                                jpName: jackpotName,
                                jpCall: jackpotCall,
                            })}
                            value={jackpotPrize || ''}
                            type="small"
                        />
                    )}
                </LowerTextContainer>
                <FlexColWrapper>
                    {gameContinued ? (
                        <GameContinuedIcon
                            src={starImage4}
                            alt="Continued Game Icon"
                        />
                    ) : (
                        <CurrentGamePart
                            gameParts={gameParts}
                            currentPart={currentPart}
                        />
                    )}

                    <FlexRowWrapper>
                        <BingoCardPattern
                            patternType={patternType}
                            parts={parts}
                            currentPart={currentPart - 1}
                            gameType={gameType}
                        />
                        <PlayersCardsWrapper
                            type="players"
                            text={gamePlayers}
                        />
                        <PlayersCardsWrapper type="cards" text={gameCards} />
                    </FlexRowWrapper>
                </FlexColWrapper>
            </LowerContainer>
            <BuyButtonWrapper isDisabled={seconds <= 20} handleClick={onBuy} />
        </CurrentGamePanelContainer>
    );
};
