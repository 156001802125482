import { FC } from 'react';
import styled from 'styled-components';

export type ToGoPanelItemProps = 'orange' | 'green' | 'pink' | 'blue';

export type ToGoPanelProps = {
    label: string;
    value: number;
};

const TogoContainer = styled.div<{ color: ToGoPanelItemProps }>`
    display: flex;
    align-items: center;
    justify-content: center;
    font-family: Signika-Bold;
    font-size: 1.49rem;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.11;
    letter-spacing: normal;
    width: 6.25rem;
    height: 2.75rem;
    margin: 0 0.625rem 0 0;
    border-radius: 0.625rem;

    ${({ color, theme }) => `
        color: ${theme.toGoPanel.color};
        border: 0.25rem solid ${theme.toGoPanel.border};
        background-color: ${theme.toGoPanel.background[color]};
    `};
`;

export const ToGoPanel: FC<ToGoPanelProps> = ({ label, value, ...props }) => {
    let color: ToGoPanelItemProps = 'blue'; //default

    if (label.includes('1')) {
        color = 'orange';
    }

    if (label.includes('2')) {
        color = 'green';
    }

    if (label.includes('3')) {
        color = 'pink';
    }

    return (
        <TogoContainer color={color} {...props}>
            {label} {value}
        </TogoContainer>
    );
};
