import { FC, HTMLAttributes } from 'react';
import styled, { useTheme } from 'styled-components';
import { useTranslation } from 'react-i18next';

import { CommonPanel } from '../CommonPanel';
import { InfoButton } from '../InfoButton';
import { PlayersCards } from '../PlayersCards';
import { PrimaryPanelText } from '../PrimaryPanelText';
import { GamePart, GamePartProps } from '../GamePart';
import { GameType, GameTypeProps } from '../GameType';
import { BingoCardPattern, BingoCardPatternProps } from '../BingoCardPattern';

export type GameInfoProps = BingoCardPatternProps & {
    seconds: number;
    gameTitle: string;
    patternName: string;
    gamePrize: string;
    gamePlayers: number | string;
    gameCards: number | string;
    hasJackpot: boolean;
    jackpotName?: string;
    jackpotCall?: number;
    jackpotPrize?: string;
    gameLinked?: boolean;
    gameContinued?: boolean;
    onInfoBox: () => void;
    onBuy: () => void;
};

export type CurrentGamePanelProps = HTMLAttributes<HTMLDivElement> &
    GameTypeProps &
    GamePartProps &
    GameInfoProps;

const CurrentGamePanelContainer = styled(CommonPanel)`
    height: auto;
    margin-bottom: 1rem;
`;

export const CurrentGamePart = styled(GamePart)<GameTypeProps>`
    margin-bottom: 0.625rem;

    ${({ gameType }) => `
        ${
            gameType !== 90
                ? `
                margin-right: 0.688rem;
            `
                : null
        }
    `};
`;

const UpperContainer = styled.div`
    display: flex;
    justify-content: space-between;
    width: 100%;
`;

const UpperTextContainer = styled.div`
    display: flex;
    flex-direction: column;
    flex-basis: 65%;
`;

const UpperImageContainer = styled.div<GameTypeProps>`
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-basis: ${({ gameType }) => (gameType === 90 ? '26%' : '35%')};
`;

const PartPatternContainer = styled.div<GameTypeProps>`
    display: flex;
    justify-content: flex-end;

    ${({ gameType }) => `
        align-items: ${gameType === 90 ? 'flex-end' : 'center'};
        flex-basis: ${gameType === 90 ? '60%' : '68%'};
        flex-direction: ${gameType === 90 ? 'column' : 'row'};
    `};
`;

const Divider = styled.hr`
    width: 91%;
    margin-left: 0;
    border-width: 0;
    height: 0.125rem;
    background-color: ${({ theme: { primaryPanel } }) =>
        primaryPanel.color.divider};
`;

const LowerContainer = styled.div`
    display: flex;
    width: 100%;
    margin-top: 0.5rem;
`;

const LowerTextContainer = styled.div`
    display: flex;
    justify-content: center;
    flex-direction: column;
    width: 55%;
`;

const LowerImageContainer = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 45%;
`;

export const GameContinuedIcon = styled.img`
    width: 2rem;
    height: 2rem;
`;

export const CurrentGamePanelMobile: FC<CurrentGamePanelProps> = ({
    gameTitle,
    patternName,
    gamePrize,
    gamePlayers,
    gameCards,
    hasJackpot,
    jackpotName,
    jackpotCall,
    jackpotPrize,
    currentPart,
    gameParts,
    gameType,
    patternType,
    parts,
    gameLinked,
    gameContinued,
    onInfoBox,
    ...props
}) => {
    const { t } = useTranslation();
    const theme = useTheme();

    const { starImage4 } = theme.images;
    const CURRENT_GAME = t('infoBox.currentGame');

    return (
        <CurrentGamePanelContainer {...props} type="primary">
            <UpperContainer>
                <UpperTextContainer>
                    <PrimaryPanelText
                        label={CURRENT_GAME}
                        value={gameTitle}
                        type="big"
                        gameLinked={gameLinked}
                    />
                    <Divider />
                </UpperTextContainer>
                <UpperImageContainer gameType={gameType}>
                    <PartPatternContainer gameType={gameType}>
                        {gameContinued ? (
                            <GameContinuedIcon
                                src={starImage4}
                                alt="Continued Game Icon"
                            />
                        ) : (
                            <CurrentGamePart
                                gameParts={gameParts}
                                currentPart={currentPart}
                                gameType={gameType}
                            />
                        )}

                        <BingoCardPattern
                            patternType={patternType}
                            parts={parts}
                            currentPart={currentPart - 1}
                            gameType={gameType}
                        />
                    </PartPatternContainer>
                    <InfoButton handleClick={onInfoBox} />
                </UpperImageContainer>
            </UpperContainer>
            <LowerContainer>
                <LowerTextContainer>
                    <PrimaryPanelText
                        label={patternName}
                        value={gamePrize}
                        type="small"
                    />
                    {hasJackpot && (
                        <PrimaryPanelText
                            label={t('infoBox.jackpotFormatting', {
                                jpName: jackpotName,
                                jpCall: jackpotCall,
                            })}
                            value={jackpotPrize || ''}
                            type="small"
                        />
                    )}
                </LowerTextContainer>
                <LowerImageContainer>
                    <GameType gameType={gameType} />
                    <PlayersCards type="players" text={gamePlayers} />
                    <PlayersCards type="cards" text={gameCards} />
                </LowerImageContainer>
            </LowerContainer>
        </CurrentGamePanelContainer>
    );
};
