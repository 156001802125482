import { FC } from 'react';
import styled, { css, DefaultTheme, useTheme } from 'styled-components';
import { useTranslation } from 'react-i18next';
import { BuyButton, BuyButtonRawProps } from '../BuyButton';
import { BingoCardPattern, BingoCardPatternProps } from '../BingoCardPattern';
import { CommonButton, CommonButtonText } from '../CommonButton';
import {
    CommonModal,
    CommonModalContainer,
    CommonModalProps,
} from '../CommonModal';
import { CountdownTimer } from '../CountdownTimer';
import { GameInfoProps } from '../CurrentGamePanel/mobile';
import { GamePart, GamePartProps, GamePartDot } from '../GamePart';
import { GameType, GameTypeProps } from '../GameType';
import { InfoButton } from '../InfoButton';
import { ModalTitle } from '../ModalTitle';
import { PlayersCards } from '../PlayersCards';
import { SpecialBanner } from '../SpecialBanner';
import {
    DecrementButtonWrapper,
    IncrementButtonWrapper,
    InitialValue,
    PlusMinusInput,
} from '../PlusMinusInput';
import { QuickTicketPick } from '../../hooks/use-quick-ticket.pick';
import { useSoundHandler } from '../../hooks/use-sound-handler';

export type ButtonProps = Omit<BuyButtonRawProps, 'handleClick' | 'total'> & {
    onBuy: () => void;
    onGameInfo: () => void;
};

export type BuyModalProps = CommonModalProps &
    GameTypeProps &
    GamePartProps &
    BingoCardPatternProps &
    Omit<GameInfoProps, 'patterns' | 'matches' | 'onInfoBox' | 'seconds'> &
    ButtonProps &
    QuickTicketPick & {
        cash: string;
        bonus: string;
        wagered: string;
        special?: string;
        ticketPrice: string;
        boughtTickets: number;
        boughtFreeTickets: number;
        totalPromoCards: number;
        gameLinked?: boolean;
        gameContinued?: boolean;
        stripOnly: boolean;
        activation: boolean;
        countdownDate: Date;

        onAutoBuy: () => void;
        onPickCards: () => void;
    };

export const fontStyle = css`
    font-family: Signika-Bold;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    letter-spacing: normal;
`;

export const buyModalCardsStyle = ({
    theme: {
        buyModal: { cards },
    },
}: {
    theme: DefaultTheme;
}) => `
    text-align: center;
    border: solid 0.063rem ${cards.border};
    background-color: ${cards.background};
    box-shadow: inset 0 0.25rem 0 0 ${cards.boxShadow.first},
        0 0.25rem 0 0 ${cards.boxShadow.second};
`;

export const cashBonusTicketStyle = css`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 32.5%;
    height: 5rem;
    font-size: 1.5rem;
    ${fontStyle};
    ${buyModalCardsStyle};
`;

export const enabledStyle = ({ enabled = true }: { enabled?: boolean }) => {
    if (enabled) {
        return;
    }

    return css`
        opacity: 0.5;
        cursor: not-allowed;
    `;
};

export const autoBuyButtonStyle = ({
    theme: {
        buyModal: { autobuy },
    },
}: {
    theme: DefaultTheme;
}) => {
    return `
        color: ${autobuy.color};
        box-shadow: inset 0 0.188rem 0 0 ${autobuy.boxShadow.first},
            inset 0 -0.188rem 0 0 ${autobuy.boxShadow.second};
        border: solid 0.075rem ${autobuy.border};
        background-image: linear-gradient(to bottom, ${autobuy.backgroundGradient.upper}, ${autobuy.backgroundGradient.lower} 99%);
    `;
};

export const autoBuyCircleStyle = ({
    activation,
    theme: {
        buyModal: { circle },
    },
}: {
    theme: DefaultTheme;
    activation: boolean;
}) => `

    ${
        activation
            ? `
    background-color: ${circle.background.active};
    box-shadow: inset 0 0.125rem 0 0 ${circle.boxShadow.active.first}, inset 0 -0.125rem 0 0 ${circle.boxShadow.active.second};
    `
            : `
            background-color: ${circle.background.inactive};
    box-shadow: inset 0 0.125rem 0 0 ${circle.boxShadow.inactive.first}, inset 0 -0.125rem 0 0 ${circle.boxShadow.inactive.second};
            `
    }


`;

export const clearButtonStyle = ({
    theme: {
        buyModal: { clear },
    },
}: {
    theme: DefaultTheme;
}) => `
    box-shadow: inset 0 0.313rem 0 0 ${clear.boxShadow.first},
        inset 0 -0.313rem 0 0 ${clear.boxShadow.second};
    background-image: linear-gradient(to bottom, ${clear.backgroundGradient.upper}, ${clear.backgroundGradient.lower} 99%);
`;

export const BuyModalContainer = styled(CommonModalContainer)`
    width: 38.75rem;
    height: auto;
    padding: 1.813rem 1.625rem;
    margin-bottom: 8rem;
`;

export const FlexRowWrapper = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
`;

export const FlexColWrapper = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
`;

export const ModalTitleWrapper = styled(ModalTitle)`
    font-size: 2.625rem;
    line-height: 2.625rem;
`;

export const TimerWrapper = styled(CountdownTimer)`
    font-size: 5.5rem;
    line-height: 5.5rem;
`;

export const GameTitleWrapper = styled.div`
    font-size: 1.875rem;
    line-height: 2.2rem;
    width: 80%;
    ${fontStyle};
`;

export const BuyModalLabel = styled.div`
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    color: ${({
        theme: {
            buyModal: { color },
        },
    }) => color.label};
`;

export const PatternNamePriceLabel = styled.div`
    display: flex;
`;

export const BuyModalAccountLabel = styled(BuyModalLabel)`
    width: 98%;
`;

export const LinkedGameIcon = styled.img`
    width: 1rem;
    height: 1rem;
    margin-left: 0.5rem;
`;

export const GameContinuedIcon = styled.img`
    width: 2.5rem;
    height: 2.5rem;
    margin-left: 0.5rem;
`;

export const PatternNameLabel = styled.div`
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    margin-right: 0.313rem;
`;

export const BuyModalValue = styled.span`
    color: ${({
        theme: {
            buyModal: { color },
        },
    }) => color.value};
`;

export const GameTitleJackpot = styled.div`
    color: ${({
        theme: {
            buyModal: { color },
        },
    }) => color.jackpot};
`;

export const GamePartContainer = styled.div`
    display: flex;
    justify-content: flex-end;
    align-items: flex-end;
    align-self: stretch;
    width: 20%;
    padding-bottom: 0.625rem;
`;

export const GamePartWrapper = styled(GamePart)`
    justify-content: flex-end;

    ${GamePartDot} {
        width: 0.938rem;
        height: 0.938rem;
    }
`;

export const GameTypeWrapper = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    align-self: stretch;
    flex-basis: 55%;
`;

export const PlayersCardsWrapper = styled(PlayersCards)`
    background-image: none;
    ${buyModalCardsStyle};
`;

export const GamePatternWrapper = styled.div<GameTypeProps>`
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-basis: ${({ gameType }) => (gameType === 90 ? '33%' : '25%')};
`;

export const CashCard = styled.div`
    border-radius: 0.375rem 0 0 0.375rem;
    ${cashBonusTicketStyle};
`;

export const BonusCard = styled.div`
    ${cashBonusTicketStyle};
`;

export const TicketPriceCard = styled.div`
    border-radius: 0 0.375rem 0.375rem 0;
    ${cashBonusTicketStyle};
`;

export const SpecialBannerWrapper = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 1.375rem;
`;

export const BlueButton = styled.button`
    display: flex;
    justify-content: center;
    align-items: center;
    flex-basis: 48%;
    height: 3.75rem;
    font-size: 1.625rem;
    border-radius: 0.75rem;
    cursor: pointer;

    ${autoBuyButtonStyle};
    ${enabledStyle};
    ${fontStyle};
`;

export const PickCardImage = styled.img`
    width: 1.8125rem;
    height: 2rem;
    margin-right: 0.59375rem;
`;

export const AutobuyCircle = styled.div<{ activation: boolean }>`
    width: 1.125rem;
    height: 1.125rem;
    border-radius: 50%;
    margin-right: 1.313rem;

    ${autoBuyCircleStyle};
`;

export const TicketInfoContainer = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 1.5rem;
    width: 100%;
    height: 3.75rem;
    border-radius: 0.375rem;
    padding: 0.75rem 0.875rem;
    margin-bottom: 1.688rem;
    ${fontStyle};
    ${buyModalCardsStyle};
`;

export const ClearButton = styled(CommonButton)`
    width: 7.5rem;
    height: 4.375rem;

    ${enabledStyle};
    ${clearButtonStyle};
    ${CommonButtonText} {
        font-size: 1.625rem;
    } ;
`;

export const MaxButton = styled(CommonButton)`
    width: 7.5rem;
    height: 4.375rem;

    ${enabledStyle};
    ${CommonButtonText} {
        font-size: 1.625rem;
    } ;
`;

export const TicketInputWrapper = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    flex-basis: 52%;
    font-size: 1.5rem;
    ${fontStyle};
`;

const PlusMinusInputWrapper = styled(PlusMinusInput)`
    width: 12.125rem;
    height: 10.125rem;
    margin: 0.375rem 0;
    border-radius: 0.75rem;
    border: ${({ theme: { buyModal } }) =>
        '0.313rem solid ' + buyModal.inputBorder};

    ${InitialValue} {
        font-size: 4.75rem;
    }

    ${IncrementButtonWrapper} {
        width: 4.375rem;
        height: 4.375rem;
        margin-right: -3.125rem;
    }

    ${DecrementButtonWrapper} {
        width: 4.375rem;
        height: 4.375rem;
        margin-left: -3.125rem;
    } ;
`;

export const BuyModalMobile: FC<BuyModalProps> = ({
    cash,
    bonus,
    wagered,
    parts,
    special,
    gameType,
    gameTitle,
    gamePrize,
    gameCards,
    hasJackpot,
    jackpotName,
    jackpotCall,
    gamePlayers,
    patternName,
    patternType,
    boughtFreeTickets,
    totalPromoCards,
    ticketPrice,
    jackpotPrize,
    boughtTickets,
    isDisabled,
    countdownDate,
    gameLinked,
    gameContinued,
    currentPart,
    gameParts,
    stripOnly,
    activation,

    onBuy,
    onGameInfo,
    onAutoBuy,
    onPickCards,

    min,
    max,
    hasIncrementalChange,
    buying,
    tickets,
    freeTickets,
    total,
    increment,
    decrement,
    minimize,
    maximize,

    ...props
}) => {
    const { t } = useTranslation();

    const theme = useTheme();
    const { pickCards, link, starImage4 } = theme.images;

    const cardType = stripOnly
        ? t('modalPurchase.cardTypeStrips')
        : t('modalPurchase.cardTypeTickets');

    const $onAutoBuy = useSoundHandler(onAutoBuy, 'click');
    const $onPickCards = useSoundHandler(onPickCards, 'click');
    const $onBuy = useSoundHandler(onBuy, 'buy');
    const $onGameInfo = useSoundHandler(onGameInfo, 'click');

    return (
        <CommonModal {...props} Container={BuyModalContainer}>
            <FlexColWrapper style={{ marginBottom: '1.313rem' }}>
                <ModalTitleWrapper title={t('modalPurchase.nextGame')} />
                <TimerWrapper to={countdownDate} />
            </FlexColWrapper>
            <FlexRowWrapper style={{ marginBottom: '0.875rem' }}>
                <GameTitleWrapper>
                    <BuyModalLabel>
                        {gameTitle}
                        {gameLinked && (
                            <LinkedGameIcon src={link} alt="Link Icon" />
                        )}
                    </BuyModalLabel>
                    <PatternNamePriceLabel>
                        <PatternNameLabel>
                            {patternName ? `${patternName}:` : ''}
                        </PatternNameLabel>
                        <BuyModalValue>{gamePrize}</BuyModalValue>
                    </PatternNamePriceLabel>
                    {hasJackpot && (
                        <GameTitleJackpot>
                            {`${t('infoBox.jackpotFormatting', {
                                jpName: jackpotName,
                                jpCall: jackpotCall,
                            })}: ${jackpotPrize}`}
                        </GameTitleJackpot>
                    )}
                </GameTitleWrapper>
                <GamePartContainer>
                    {gameContinued ? (
                        <GameContinuedIcon
                            src={starImage4}
                            alt="Continued Game Icon"
                        />
                    ) : (
                        <GamePartWrapper
                            style={{ width: '6.375rem', height: '1rem' }}
                            gameParts={gameParts}
                            currentPart={currentPart}
                        />
                    )}
                </GamePartContainer>
            </FlexRowWrapper>
            <FlexRowWrapper
                style={{
                    marginBottom: '0.875rem',
                }}
            >
                <GameTypeWrapper>
                    <GameType gameType={gameType} />
                    <PlayersCardsWrapper type="players" text={gamePlayers} />
                    <PlayersCardsWrapper type="cards" text={gameCards} />
                </GameTypeWrapper>
                <GamePatternWrapper gameType={gameType}>
                    <BingoCardPattern
                        patternType={patternType}
                        parts={parts}
                        gameType={gameType}
                        currentPart={currentPart - 1}
                    />
                    <InfoButton handleClick={$onGameInfo} />
                </GamePatternWrapper>
            </FlexRowWrapper>
            <FlexRowWrapper
                style={{
                    marginBottom: '1.063rem',
                }}
            >
                <CashCard>
                    <BuyModalValue>{cash}</BuyModalValue>
                    <BuyModalAccountLabel>
                        {t('modalPurchase.playerBalCash')}
                    </BuyModalAccountLabel>
                </CashCard>
                <BonusCard>
                    <BuyModalValue>{bonus}</BuyModalValue>
                    <BuyModalAccountLabel>
                        {t('modalPurchase.playerBalBonus')}
                    </BuyModalAccountLabel>
                </BonusCard>
                <TicketPriceCard>
                    <BuyModalValue>{ticketPrice}</BuyModalValue>
                    <BuyModalAccountLabel>
                        {t('modalPurchase.cardOrStripePrice', {
                            type: cardType,
                        })}
                    </BuyModalAccountLabel>
                </TicketPriceCard>
            </FlexRowWrapper>
            <FlexRowWrapper style={{ marginBottom: '1.25rem' }}>
                <BlueButton
                    onClick={isDisabled ? void 0 : $onPickCards}
                    enabled={!isDisabled}
                >
                    <PickCardImage src={pickCards} alt="Pick Cards" />
                    {t('modalPurchase.btnPickCards')}
                </BlueButton>
                <BlueButton onClick={$onAutoBuy}>
                    <AutobuyCircle activation={activation} />
                    {t('modalPurchase.btnAutobuy')}
                </BlueButton>
            </FlexRowWrapper>
            <TicketInfoContainer>
                <BuyModalLabel>{t('modalPurchase.boughtCards')}:</BuyModalLabel>
                <BuyModalValue style={{ marginRight: '0.625rem' }}>
                    {boughtTickets - totalPromoCards}
                </BuyModalValue>
                <BuyModalLabel>{t('modalPurchase.wagered')}:</BuyModalLabel>
                <BuyModalValue style={{ marginRight: '0.625rem' }}>
                    {wagered}
                </BuyModalValue>
                <BuyModalLabel>{t('modalPurchase.freeCards')}:</BuyModalLabel>
                <BuyModalValue style={{ marginRight: '0.625rem' }}>
                    {boughtFreeTickets}
                </BuyModalValue>
            </TicketInfoContainer>

            {special && (
                <SpecialBannerWrapper>
                    <SpecialBanner text={special} />
                </SpecialBannerWrapper>
            )}
            <FlexRowWrapper
                style={{
                    marginBottom: '2.125rem',
                    justifyContent: 'space-between',
                }}
            >
                <ClearButton
                    handleClick={minimize}
                    disabled={
                        isDisabled || tickets === min || !hasIncrementalChange
                    }
                >
                    {t('modalPurchase.btnClear')}
                </ClearButton>

                <TicketInputWrapper>
                    <BuyModalLabel>
                        {t('modalPurchase.selectedTickets')}
                    </BuyModalLabel>
                    <PlusMinusInputWrapper
                        min={min}
                        max={max}
                        initialValue={tickets}
                        disabled={isDisabled}
                        hasChange={hasIncrementalChange}
                        handleIncrement={increment}
                        handleDecrement={decrement}
                    />
                    <BuyModalLabel>
                        {t('modalPurchase.freeCards')}:
                        <BuyModalValue style={{ marginLeft: '0.625rem' }}>
                            {freeTickets}
                        </BuyModalValue>
                    </BuyModalLabel>
                </TicketInputWrapper>

                <MaxButton
                    handleClick={maximize}
                    disabled={
                        isDisabled || tickets === max || !hasIncrementalChange
                    }
                >
                    {t('modalPurchase.btnMax')}
                </MaxButton>
            </FlexRowWrapper>
            <FlexRowWrapper style={{ justifyContent: 'center' }}>
                <BuyButton
                    isDisabled={isDisabled}
                    withTotal={true}
                    total={total}
                    handleClick={$onBuy}
                />
            </FlexRowWrapper>
        </CommonModal>
    );
};
