import { FC, useMemo, useCallback } from 'react';
import { useHookstate } from '@hookstate/core';
import { isMobile } from 'react-device-detect';
import { NextGamePanelMobile } from '../../components/NextGamePanel/mobile';
import { NextGamePanelDesktop } from '../../components/NextGamePanel/desktop';
import { Part } from '../../components/GamePart';
import { useGameType } from '../../hooks/use-game-type';
import { useJackpot } from '../../hooks/use-jackpot';
import { useGamePart } from '../../hooks/use-game-part';
import { useCountdown } from '../../hooks/use-countdown';
import { useSoundHandler } from '../../hooks/use-sound-handler';
import { useCycleGameParts } from '../../hooks/use-cycle-game-parts';
import { useFormatCurrency } from '../../hooks/use-format-currency';
import { meta, next } from '../../stores/backend/';
import { chatMeta } from '../../stores/chat';
import { formatNumber } from '../../lib/format-number';

export const NextGamePanel: FC = () => {
    const $meta = useHookstate(meta);
    const $next = useHookstate(next);
    const $chatMeta = useHookstate(chatMeta);

    const gameType = useGameType($next);
    const jackpot = useJackpot($next, $meta);
    const { allGameParts } = useGamePart($next, $meta);
    const { seconds } = useCountdown(new Date($meta.timer.date.value));
    const formatCurrency = useFormatCurrency($next, $meta);

    const to = new Date($meta.timer.date.value);
    const gameCurrency = $next.game_currency.value;

    const parts = useMemo(
        () =>
            Object.values($next.game_parts.value || {}).map((part) => ({
                patterns: part.pat_code || [],
                matches: part.num_matches || 1,
            })),
        [$next.game_parts.value]
    );

    const size = useMemo(
        () => Object.keys(allGameParts).length,
        [allGameParts]
    );

    const index = useCycleGameParts(size);
    const prizeString = allGameParts[index]?.prizeString;

    const gamePrize =
        prizeString && prizeString.length > 1
            ? prizeString[0]
            : allGameParts[index]?.prize;

    const onInfoBox = useCallback(() => {
        $meta.modals.gameInfo.set(true);
    }, [$meta.modals.gameInfo]);

    const onBuy = () => {
        $meta.modals.buy.set(true);
    };

    const $onBuy = useSoundHandler(onBuy, 'click');

    const Component = isMobile ? NextGamePanelMobile : NextGamePanelDesktop;

    return (
        <Component
            {...jackpot}
            to={to}
            parts={parts}
            seconds={seconds}
            gameType={gameType}
            gameParts={size as Part}
            gameTitle={$next.game_desc.value}
            currentPart={(index + 1) as Part}
            patternType={gameType}
            patternName={allGameParts[index]?.desc}
            gameLinked={$next.game_linked?.value}
            gameContinued={$next.game_continued?.value}
            chatOpen={$chatMeta.chatToggle.value}
            onBuy={$onBuy}
            onInfoBox={onInfoBox}
            gameCards={formatNumber($next.game_cards.value || 0)}
            gamePlayers={formatNumber($next.game_players.value || 0)}
            gamePrize={formatCurrency(gamePrize, gameCurrency)}
        />
    );
};
