import { FC } from 'react';
import { isMobile } from 'react-device-detect';
import styled, { css, useTheme } from 'styled-components';
import { useTranslation } from 'react-i18next';
import { ModalLabel } from '../ModalLabel';
import { GamePart, GamePartDot, Part } from '../GamePart';
import {
    CommonModal,
    CommonModalContainer,
    CommonModalProps,
    CloseButtonContainer,
} from '../CommonModal';
import { ConsolationWinner } from '../../types/bingo-events/in/backend/winner-message';

export type PartWinners = {
    name: string;
    prize: string;
};

type WinnerModalProps = CommonModalProps & {
    gameName: string;
    gameId: number;
    winningCall: number;
    winningNumber: number;
    totalPrizes: string;
    recentWinners: boolean;
    gameContinued?: boolean;
    loading?: boolean;
    loaded?: boolean;
    partWinners: PartWinners[][];
    consolationWinners: ConsolationWinner[];
    onPrevious?: () => void;
    onNext?: () => void;
};

export const titleStyle = css`
    ${({ theme }) => css`
        background-image: linear-gradient(
            to bottom,
            ${theme.winnerModal.title.backgroundGradient.upper},
            ${theme.winnerModal.title.backgroundGradient.middle} 58%,
            ${theme.winnerModal.title.backgroundGradient.lower}
        );

        &:after {
            background: none;
            content: attr(data-text);
            left: 0;
            top: 0;
            z-index: -1;
            position: absolute;
            text-shadow: 0 -0.125rem 0 ${theme.winnerModal.title.textShadow.first},
                0 0.313rem 0 ${theme.winnerModal.title.textShadow.second},
                0 -0.438rem 0 ${theme.winnerModal.title.textShadow.third};
        }
    `};
`;

export const ModalTitle = styled.div`
    font-family: Signika-Bold;
    font-size: 1.625rem;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.625rem;
    letter-spacing: normal;
    text-align: center;
    color: ${({ theme }) => theme.winnerModal.title.color};
`;

export const WinnerModalContainer = styled(CommonModalContainer)`
    position: relative;
    width: 38.813rem;
    height: auto;
    min-height: 47.5rem;

    padding: 0;
    z-index: -1;

    ${isMobile
        ? ''
        : `
        left: 14rem;
        margin-top: 5.25rem;
   `};

    ${({ theme: { winnerModal } }) => css`
        border-color: ${winnerModal.borderColor};
        box-shadow: inset 0 0.15rem 0.25rem 0rem ${winnerModal.boxShadow};
        background-image: linear-gradient(
            to bottom,
            ${winnerModal.backgroundGradient.upper} 2%,
            ${winnerModal.backgroundGradient.lower} 97%
        );

        ${CloseButtonContainer} {
            z-index: 2;
            border-color: ${winnerModal.borderColor};
        }
    `};
`;

export const WinnerModalBackground = styled.img`
    position: absolute;
    top: 1.3rem;
    width: 100%;
    height: 30rem;
    z-index: -1;
`;

export const HeaderContainer = styled.div`
    position: absolute;
    width: 100%;
    top: -4.8rem;
    text-align: center;
`;

export const WinnerModalHeader = styled.img`
    width: 86%;
`;

export const ContentContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 2.5rem 3.75rem;
`;

export const ArrowTitleContainer = styled.div`
    display: flex;
    justify-content: space-around;
    align-items: center;
    width: 100%;
`;

export const Arrow = styled.div`
    font-family: Signika-Medium;
    font-size: 3rem;
    cursor: pointer;
    position: relative;
    -webkit-background-clip: text;
    background-clip: text;
    -webkit-text-fill-color: transparent;

    ${titleStyle};
`;

export const WinnerModalTitle = styled.div`
    font-family: Signika-Bold;
    font-size: 4.375rem;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 0.93;
    letter-spacing: normal;
    text-align: center;
    -webkit-background-clip: text;
    background-clip: text;
    -webkit-text-fill-color: transparent;
    position: relative;
    margin-bottom: 0.625rem;

    ${titleStyle};
`;

export const GameInfoContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 31.25rem;
    height: 5.875rem;
    border-radius: 0.788rem;
    padding: 0.25rem;
    margin-bottom: 0.875rem;
    z-index: 2;
    background-color: ${({ theme }) => theme.winnerModal.background};
`;

export const PartWinnerContainer = styled.div`
    width: 31.25rem;
    border-radius: 0.788rem;
    margin-bottom: 0.875rem;
    z-index: 2;

    height: ${isMobile ? '36rem' : '28rem'};
    background-color: ${({ theme }) => theme.winnerModal.background};
`;

export const TotalPrizesContainer = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    width: 100%;
    height: 3.313rem;
`;

export const Overlay = styled.div`
    border-radius: 0.788rem 0.788rem 0 0;
    background-color: ${({ theme }) => theme.winnerModal.background};
    position: absolute;
    width: 100%;
    height: 3.313rem;
    top: 0;
    left: 0;
    z-index: -1;
`;

export const TotalPrizesText = styled(WinnerModalTitle)`
    font-size: 2.5rem;
    margin-bottom: 0;
`;

export const PartWinnerItems = styled.div`
    padding: 1.188rem 0.938rem 1.188rem 1.813rem;
`;

export const ScrollContainer = styled.div`
    display: flex;
    flex-direction: column;
    overflow-x: auto;

    height: ${isMobile ? '30.5rem' : '22.5rem'};

    &::-webkit-scrollbar-track {
        border-radius: 0.438rem;
        background-color: ${({ theme: { winnerModal } }) =>
            winnerModal.scroll.track};
        background-clip: padding-box;
        border-left: 0.125rem solid transparent;
        border-right: 0.125rem solid transparent;
    }

    &::-webkit-scrollbar {
        width: 1.125rem;
        background-color: transparent;
    }

    &::-webkit-scrollbar-thumb {
        border-radius: 0.438rem;
        background-color: ${({ theme: { winnerModal } }) =>
            winnerModal.scroll.thumb};
    }
`;

export const FlexWrapper = styled.div`
    display: flex;
    justify-content: flex-start;
`;

export const PartWinnerItemsLeft = styled.div`
    flex-basis: 30%;
    margin-bottom: 2.875rem;
`;

export const PartWinnerItemsRight = styled.div`
    flex-basis: 70%;
    margin-bottom: 2.875rem;
`;

export const GamePartWrapper = styled(GamePart)`
    ${GamePartDot} {
        width: 1.25rem;
        height: 1.25rem;
        box-shadow: inset 0 0.188rem 0 0
            ${({ theme }) => theme.gamePart.boxShadow.first.active};
    }
`;

export const NamePrizeContainer = styled.div`
    display: flex;
`;

export const ConsolationWinnerContainer = styled.div`
    width: 31.25rem;
    height: 6.725rem;
    border-radius: 0.788rem;
    padding: 1.125rem 0.844rem;
    z-index: 2;

    background-color: ${({ theme }) => theme.winnerModal.background};
`;

export const ConsolationWinnerTitle = styled.div`
    font-family: Signika-Medium;
    font-size: 1.125rem;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    letter-spacing: normal;
    margin-bottom: 0.438rem;

    color: ${({ theme }) => theme.winnerModal.title.color};
`;

export const ConsolationWinnerNames = styled(ConsolationWinnerTitle)`
    font-size: 1rem;
    height: 3.5rem;
    margin: 0;
    overflow-x: auto;

    color: ${({ theme }) => theme.color};

    &::-webkit-scrollbar-track {
        border-radius: 0.438rem;
        background-color: ${({ theme: { winnerModal } }) =>
            winnerModal.scroll.track};
        background-clip: padding-box;
        border-left: 0.125rem solid transparent;
        border-right: 0.125rem solid transparent;
    }

    &::-webkit-scrollbar {
        width: 1.125rem;
        background-color: transparent;
    }

    &::-webkit-scrollbar-thumb {
        border-radius: 0.438rem;
        background-color: ${({ theme: { winnerModal } }) =>
            winnerModal.scroll.thumb};
    }
`;

const TopWinnerImage = styled.img`
    width: 4.375rem;
    height: 4.188rem;
    margin-top: -0.313rem;
`;

const LevelWinnerImage = styled.img`
    width: 2.75rem;
    height: 2.688rem;
    margin-top: -0.75rem;
`;

export const WinnerModal: FC<WinnerModalProps> = ({
    loading,
    loaded,
    gameName,
    gameId,
    winningCall,
    winningNumber,
    totalPrizes,
    partWinners,
    consolationWinners,
    recentWinners,
    gameContinued,
    onPrevious,
    onNext,
    ...props
}) => {
    const { t } = useTranslation();
    const theme = useTheme();
    const { headerBingo, starImage4, starImage5, starBurstCover } =
        theme.images;

    return (
        <CommonModal
            {...props}
            Container={WinnerModalContainer}
            className="ReactModal__Content--after-open-override"
        >
            <WinnerModalBackground
                src={starBurstCover}
                alt="Star Burst Background Image"
            />
            <HeaderContainer>
                <WinnerModalHeader src={headerBingo} alt="Header Bingo Image" />
            </HeaderContainer>
            <ContentContainer>
                {recentWinners ? (
                    <ArrowTitleContainer>
                        <Arrow data-text="<" onClick={onPrevious}>
                            {'<'}
                        </Arrow>
                        <WinnerModalTitle data-text={t('modalWinners.winners')}>
                            {t('modalWinners.winners')}
                        </WinnerModalTitle>
                        <Arrow data-text=">" onClick={onNext}>
                            {'>'}
                        </Arrow>
                    </ArrowTitleContainer>
                ) : (
                    <WinnerModalTitle data-text={t('modalWinners.winners')}>
                        {t('modalWinners.winners')}
                    </WinnerModalTitle>
                )}

                {loading && <div>{t('preLoader.loading')}...</div>}
                {loaded && (
                    <>
                        <GameInfoContainer>
                            <ModalLabel
                                style={{ fontSize: '2.844rem' }}
                                label={gameName}
                            />
                            <ModalTitle>
                                {gameContinued
                                    ? 'ID: ' + gameId
                                    : t('modalWinners.winnersDetails', {
                                          gameId,
                                          winningCall: winningCall,
                                          winningNumber: winningNumber,
                                      })}
                            </ModalTitle>
                        </GameInfoContainer>
                        <PartWinnerContainer>
                            <TotalPrizesContainer>
                                <Overlay />
                                <TotalPrizesText
                                    data-text={t('modalWinners.totalPrize', {
                                        total: totalPrizes,
                                    })}
                                >
                                    {t('modalWinners.totalPrize', {
                                        total: totalPrizes,
                                    })}
                                </TotalPrizesText>
                            </TotalPrizesContainer>
                            <PartWinnerItems>
                                <ScrollContainer>
                                    {partWinners.map((part, index) => {
                                        return (
                                            <FlexWrapper key={index}>
                                                <PartWinnerItemsLeft>
                                                    {gameContinued ? (
                                                        index === 0 ? (
                                                            <TopWinnerImage
                                                                src={starImage4}
                                                                alt="Top Winner Image"
                                                            />
                                                        ) : (
                                                            <LevelWinnerImage
                                                                src={starImage5}
                                                                alt="Level Winner Image"
                                                            />
                                                        )
                                                    ) : (
                                                        <GamePartWrapper
                                                            gameParts={
                                                                (index +
                                                                    1) as Part
                                                            }
                                                            currentPart={
                                                                (index +
                                                                    1) as Part
                                                            }
                                                        />
                                                    )}
                                                </PartWinnerItemsLeft>
                                                <PartWinnerItemsRight>
                                                    <ModalTitle
                                                        style={{
                                                            lineHeight:
                                                                '1.75rem',
                                                            textAlign: 'left',
                                                        }}
                                                    >
                                                        {gameContinued
                                                            ? index === 0
                                                                ? t(
                                                                      'modalWinners.topWinner'
                                                                  )
                                                                : t(
                                                                      'modalWinners.level',
                                                                      {
                                                                          level:
                                                                              index +
                                                                              1,
                                                                      }
                                                                  )
                                                            : t(
                                                                  'modalWinners.part',
                                                                  {
                                                                      part:
                                                                          index +
                                                                          1,
                                                                  }
                                                              )}
                                                    </ModalTitle>
                                                    {part.map((item, index) => (
                                                        <NamePrizeContainer
                                                            key={index}
                                                        >
                                                            <ModalLabel
                                                                style={{
                                                                    fontSize:
                                                                        '1.625rem',
                                                                    flexBasis:
                                                                        '60%',
                                                                }}
                                                                label={
                                                                    item.name
                                                                }
                                                            />
                                                            <ModalLabel
                                                                style={{
                                                                    fontSize:
                                                                        '1.625rem',
                                                                    flexBasis:
                                                                        '30%',
                                                                }}
                                                                label={
                                                                    item.prize
                                                                }
                                                            />
                                                        </NamePrizeContainer>
                                                    ))}
                                                </PartWinnerItemsRight>
                                            </FlexWrapper>
                                        );
                                    })}
                                </ScrollContainer>
                            </PartWinnerItems>
                        </PartWinnerContainer>
                        {consolationWinners.length > 0 && (
                            <ConsolationWinnerContainer>
                                <ConsolationWinnerTitle>
                                    {t('modalWinners.consolationWinners', {
                                        amount: consolationWinners[0].amount,
                                    })}
                                </ConsolationWinnerTitle>
                                <ConsolationWinnerNames>
                                    {consolationWinners
                                        .map((item) => item.userAlias)
                                        .join(', ')}
                                </ConsolationWinnerNames>
                            </ConsolationWinnerContainer>
                        )}
                    </>
                )}
            </ContentContainer>
        </CommonModal>
    );
};
